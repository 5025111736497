

<template>
  <div >
    <h4>我的成绩</h4>
    <el-empty :image-size="200" v-if="tableData.length===0" description="暂无数据">
      <span style="font-size: 12px;color:#949599">提示：待老师发布成绩后，即可看到课题的成绩！！！</span>
    </el-empty>
    <div v-else>
      <el-table
          :data="tableData"
          :cell-style="cellStyle"
          stripe
          :header-cell-style="{
      backgroundColor:'#EBF1FA' ,color:'#000000'}"
          style="width: 100%">
        <el-table-column
            prop="topic.topicName"
            label="课题名称"
            >
        </el-table-column>
        <el-table-column
            prop="studentScore.regularGrade"
            label="平时成绩"
            >
        </el-table-column>
        <el-table-column
            prop="studentScore.finalGrade"

            label="期末成绩">
        </el-table-column>
        <el-table-column

            prop="studentScore.totalMark"
            label="总成绩">
        </el-table-column>
        <el-table-column

            prop="studentScore.remark"
            label="备注">
        </el-table-column>
      </el-table>
    </div>

  </div>
</template>


<script>
import axios from "axios";
import {endLoading, startLoading} from "@/axios/loading";

export default {
  name: "StudentTopic",
  data() {
    return {
      tableData: []
    }
  },
  mounted() {
    startLoading()
    axios.get("/api/score").then(resp=>{
      console.log(resp.data)
      if (resp.data.code === 200) {
        this.tableData=resp.data.data
      }

    }).catch(error=>{
      console.log(error)
    }).finally(()=>{
      endLoading()
    })
  },
  methods:{
    cellStyle({ row, column, rowIndex, columnIndex }){
      if (columnIndex === 4) {
        if (row.studentScore.totalMark < 60) {
          return 'color:red'
        }
        return ''

      }
    }
  }
}
</script>


<style scoped lang="less">

</style>