<template>
  <div>
    <div>
      <h4 style="margin-top: 0">学生成绩信息</h4>
      <hr style="background-color:#EBEEF5; border:none; height:1px;margin-bottom: 10px">
      <div>
        <vs-select placeholder="选择课题" v-model="selectShow.topicId" v-if="topicList!==''">
          <vs-option :label="'当前展示的课题：'+item.topicName" :value="item.topicId" v-for="(item,i) in topicList"
                     :key="i">
            《{{ item.topicName }}》
          </vs-option>
        </vs-select>
      </div>
      <div>
        <el-table
            :data="tableData"
            stripe
            style="width: 100%">
          <el-table-column
              type="index"
              label="序号"
              width="120">
          </el-table-column>

          <el-table-column
              prop="student.name"
              label="姓名"
              width="180">
          </el-table-column>
          <el-table-column
              prop="student.sex"
              label="性别"
          >
          </el-table-column>
          <el-table-column
              prop="studentScore.finalAcademicRecordFileId"
              label="课题报告"
          >
            <template slot-scope="scope">
              <el-tag type="danger" size="small" v-if="scope.row.studentScore.finalAcademicRecordFileId===undefined">
                暂未提交
              </el-tag>
              <el-tag size="small" effect="plain" style="cursor: pointer" v-else
                      @click="downloadAxios(2,scope.row.topic.topicId)">点击下载
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column
              prop="studentScore.regularGrade"
              label="平时成绩">
            <template slot-scope="scope">
              <el-tag type="info" size="small" v-if="scope.row.studentScore.regularGrade===undefined">暂未发布成绩
              </el-tag>
              <el-tag size="small" v-else>
                &nbsp;&nbsp;&nbsp;{{ scope.row.studentScore.regularGrade }}&nbsp;&nbsp;&nbsp;
              </el-tag>

            </template>
          </el-table-column>
          <el-table-column
              prop="studentScore.finalGrade"
              label="期末成绩">
            <template slot-scope="scope">
              <el-tag type="info" size="small" v-if="scope.row.studentScore.finalGrade===undefined">暂未发布成绩
              </el-tag>
              <el-tag size="small" v-else>&nbsp;&nbsp;&nbsp;{{ scope.row.studentScore.finalGrade }}&nbsp;&nbsp;&nbsp;
              </el-tag>
            </template>

          </el-table-column>
          <el-table-column
              prop="studentScore.totalMark"
              label="总成绩"
          >
            <template slot-scope="scope">
              <el-tag type="info" size="small" v-if="scope.row.studentScore.totalMark===undefined">暂未发布成绩</el-tag>
              <el-tag size="small" v-else>&nbsp;&nbsp;&nbsp;{{ scope.row.studentScore.totalMark }}&nbsp;&nbsp;&nbsp;
              </el-tag>

            </template>
          </el-table-column>

          <el-table-column
              label="操作"
              width="100"
          >
            <template slot-scope="scope">
              <el-button
                  size="mini"
                  type="danger"
                  @click="handleEdit(scope.$index, scope.row)">编辑
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="display: flex;justify-content: center;margin-top: 20px">
          <el-pagination
              background
              layout="prev, pager, next"
              :current-page="selectShow.page"
              :total=" dataTotal"
              :hide-on-single-page="true"
              @current-change="currentChange"
              :page-size="10">
          </el-pagination>
        </div>

      </div>
    </div>
    <div class="edit">

      <vs-dialog overflow-hidden v-model="active" prevent-close>
        <template #header>
          <h3 style="margin: 0;">
            成绩发布
          </h3>
        </template>
        <hr style="background-color:#DCDFE6; border:none; height:1px">
        <div class="content">
          <p style="background-color: #FFFBDD;font-size: 12px;padding: 10px">平时成绩与期末成绩的占比在课题发布时已经确定，<br>
            当前课题：<span style="color: red">《{{ topic.topicName }}》</span> 平时/期末占比&nbsp;&nbsp;<span
                style="color: red">{{ topic.gradeRatio }}</span>。<br><br>
            <span style="color: red">温馨提醒：请确定好成绩后再发布！！！</span>

          </p>
          <div class="score">

            <div>
              <span> 平时 成绩：</span>
              <vs-input
                  color="#7d33ff"
                  v-model="inpScore.regularGrade"
                  type="number"
                  @keyup="scoreRule"
                  placeholder="输入 平时成绩"/>
            </div>
            <div> <span>期末 成绩：</span>
              <div>
                <vs-input
                    color="#7d33ff"
                    type="number"
                    @keyup="scoreRule"
                    v-model="inpScore.finalGrade"
                    placeholder="输入 期末成绩"
                   />
              </div></div>
            <div> <span>总   成绩：</span>
              <div>
                <vs-input
                    color="#7d33ff"
                    state="success"
                    type="number"
                    disabled
                    v-model="inpScore.totalMark"
                    />
              </div></div>

            <el-input
                type="textarea"
                placeholder="请给学生留言..."
                v-model="inpScore.remark"
                maxlength="60"
                show-word-limit
                size="small"
                resize="none"
                @keydown.enter.native="handleTextareaKeydown"
                rows="2"
            >
            </el-input>
          </div>
          <div class="submit">
            <vs-button @click="active2=!active2">
              发 布
            </vs-button>
            <vs-button @click="active=!active" dark transparent>
              取 消
            </vs-button>
          </div>
        </div>
      </vs-dialog>
      <vs-dialog v-model="active2">
        <template #header>
          <h4 class="not-margin">
            是 否 确 认 发 布 ？
          </h4>
        </template>
        <template #footer>

          <vs-button block @click="submitScore">
            确定
          </vs-button>

        </template>
      </vs-dialog>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {endLoading, startLoading, startSuccessNotification} from "@/axios/loading";
import downloadAxios from "@/axios/downloadFileAxios";

export default {
  name: 'StudentScore',
  data: () => {
    return {
      active: false,
      active2: false,
      topic: {
        topicId: '',
        topicName: '',
        gradeRatio: 0.5,
      },


      selectShow: {
        topicId: 0,
        page: 1,
      },
      dataTotal: 0,
      topicList: '',
      tableData: [
        {
          student: {
            studentId: '',
            name: '',
            sex: ''
          },
          studentScore: {
            studentScoreId: '',
            topicId: '',
            regularGrade: '',
            finalGrade: '',
            totalMark: '',
            remark: '',
            finalAcademicRecordFileId: '',
          },
          topic: {
            topicId: '',
            topicName: '',
            gradeRatio: '',

          }
        }
      ],
      regularGrade: 0,
      finalGrade: 0,
      inpScore: {
        studentId: '',
        topicId: '',
        regularGrade: '',
        finalGrade: '',
        totalMark: '',
        remark: '',
      }
    }
  },
  computed: {
    watchScore() {
      const {regularGrade, finalGrade} = this.inpScore
      return {regularGrade, finalGrade}
    }
  },
  watch: {
    selectShow: {
      deep: true,
      handler(newVal) {
        startLoading()
        axios.get("/api/score/page/" + newVal.page + "/" + newVal.topicId).then(resp => {
          if (resp.data.code === 200) {
            this.tableData = resp.data.data.records
            this.dataTotal = resp.data.data.total

          }
        }).catch(error => {
          console.log(error)
        }).finally(() => {
          endLoading()
        })
      }
    },
    watchScore: {
      deep: true,
      handler(newVal) {
        this.inpScore.totalMark = newVal.regularGrade * this.topic.gradeRatio + newVal.finalGrade * (1 - this.topic.gradeRatio)
      }
    }
  },
  mounted() {
    startLoading()
    axios.get("/api/topic/names").then(resp => {
      if (resp.data.code === 200) {
        this.topicList = resp.data.data
        this.topicList.length === 0 ? this.topicId = 0 : this.topicId = this.topicList[0].topicId
        this.selectShow.topicId = this.topicList[0].topicId
      }
    }).catch(error => {
      console.log(error)
    }).finally(() => {
      endLoading()
    })

  },
  methods: {
    downloadAxios,
    handleEdit(index, row) {
      this.active = true
      this.topic = row.topic
      this.inpScore.topicId = row.topic.topicId;
      this.inpScore.studentId = row.student.studentId
    },
    submitScore() {
      startLoading()

      axios.post("/api/score", this.inpScore).then(resp => {
        if (resp.data.code === 200) {
          console.log(resp.data)
          startSuccessNotification("发布成绩成功！！！")
          setTimeout(() => {
            this.$router.go(0)
          }, 300)

        } else startSuccessNotification("发布成绩失败！！！", resp.data.msg, 2)

      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.active = !this.active
        this.active2 = !this.active2
        endLoading()
      })

    },

    handleTextareaKeydown() {
      let e = window.event || arguments[0];
      if (e.key === 'Enter' || e.code === 'Enter' || e.keyCode === 13) {
        e.returnValue = false;
        return false;
      }
    },
    scoreRule(){


      if (this.inpScore.regularGrade > 100 ) {
        this.inpScore.regularGrade=100
      }
      if (this.inpScore.regularGrade <0 ) {
        this.inpScore.regularGrade=0
      }
     if(this.inpScore.finalGrade>100){
       this.inpScore.finalGrade=100
     }
      if(this.inpScore.finalGrade<0){
        this.inpScore.finalGrade=0
      }
    },
    currentChange(i) {
      this.selectShow.page = i
    }

  }
};
</script>

<style scoped lang="less">
.content {
  width: 500px;
}

.score {
  >div{
    display: flex;
    margin: 10px 0;
    > span {
      display: inline-block;
      width: 85px;
      text-align: right;
      font-size: 14px;
      height: 33px;
      line-height: 33px;
      color: #575757;
    }
  }

}

.submit {
  display: flex;
  justify-content: right;
  padding: 15px 40px 0 0;
}
</style>