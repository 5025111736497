<template>
  <div
      class="content">
    <h3 style="text-align: center">{{ title }}</h3>
    <el-divider>正文</el-divider>
    <div class="form">

      <vs-input v-model="form.topicName" border class="form-item" label="课题名"
                placeholder="请输入课题名"
      >

        <template v-if=" (form.topicName.trim().length>10||form.topicName.trim().length<3)&&form.topicName!==''"
                  #message-warn>
          长度应在 3~10之间
        </template>
        <template v-else #message-danger>
          *必填
        </template>
      </vs-input>
      <vs-select
          v-model="form.topicCredit"
          class="form-item"
          color="success"
          label="学分"
          placeholder="选择学分"
          style="max-width: 300px"
      >

        <vs-option label="1.0学分" value="1">
          1学分
        </vs-option>
        <vs-option label="2.0学分" value="2">
          2学分
        </vs-option>
        <vs-option label="3.0学分" value="3">
          3学分
        </vs-option>
        <vs-option label="4.0学分" value="4">
          4学分
        </vs-option>
        <vs-option label="5.0学分" value="5">
          5学分
        </vs-option>

      </vs-select>

      <el-input v-model="form.topicIntroduce"

                class="form-item"
                maxlength="100"
                placeholder="请输入课题简介"
                resize="none"
                rows="6"
                show-word-limit
                type="textarea"
      ></el-input>


      <vs-input
          v-model="form.topicPeriod"
          border
          class="form-item"
          label="学时"
          max="30"
          min="1"
          placeholder="1-30学时"
          type="number"
      >
        <template v-if=" (form.topicPeriod>30||form.topicPeriod<1)&&form.topicMaxPeople!==''" #message-danger>
          学时应在1~30之间
        </template>
      </vs-input>
      <vs-input
          v-model="form.topicMaxPeople"
          border
          class="form-item"
          label="课程容纳人数"
          max="15"
          min="10"
          placeholder="最大30人"
          type="number"

      >
        <template v-if=" (form.topicMaxPeople>15||form.topicMaxPeople<10)&&form.topicMaxPeople!==''" #message-danger>
          人数应该在10~15人
        </template>

      </vs-input>
      <el-divider></el-divider>
      <vs-select
          v-model="form.gradeRatio"
          class="form-item"
          label="成绩占比"
          placeholder="平时成绩/总成绩的占比"
          state="primary"
          style="max-width: 300px"
      >
        <vs-option label="平时成绩/总成绩的占比=0.4" value="0.4">
          0.4
        </vs-option>
        <vs-option label="平时成绩/总成绩的占比=0.5" value="0.5">
          0.5
        </vs-option>
        <vs-option label="平时成绩/总成绩的占比=0.6" value="0.6">
          0.6
        </vs-option>


      </vs-select>
      <vs-button @click="againActive=true;">
        {{ title }}
      </vs-button>
      <vs-dialog v-model="againActive">
        <template #header>
          <h4 class="not-margin">
            确认要提交吗？
          </h4>
        </template>
        <div style="height: 60px; display: flex;justify-content: right">
          <vs-button style="width: 80px" transparent @click="selectAddOrModify">
            确认
          </vs-button>
          <vs-button dark transparent @click="againActive=false">
            取消
          </vs-button>
        </div>
      </vs-dialog>
    </div>

  </div>
</template>

<script>


import {endLoading, startLoading, startSuccessNotification} from "@/axios/loading";
import axios from "axios";

export default {
  data() {
    return {
      title: '发布课题',
      //提交时候的确认弹窗
      againActive: false,
      form: {
        //课程名
        topicName: '',
        //简介
        topicIntroduce: '',
        //学分
        topicCredit: '',
        //学时
        topicPeriod: 15,
        //当前人数
        memberNum: 0,
        //容纳量
        topicMaxPeople: 15,
        //分数比
        gradeRatio: 0.5,
        //创建时间
        createTime: '',
        //修改时间
        updateTime: ''
      },
      //true表示通过验证
      formSuccess: {
        topicName: false,
        topicIntroduce: false,
        topicCredit: false,
        topicPeriod: true,
        topicMaxPeople: true,
        gradeRatio: true,
      }
    }
  },
  props: ['tr', 'back'],
  watch: {
    tr: {
      immediate: true,
      deep: true,
      handler(tr) {
        this.initModifyTopic(tr);
      }
    }
  },
  methods: {
    //选择提交的时候 时修改还是提交
    selectAddOrModify() {
      //if成立说明tr为空 没有数据传过来，说明是在添加页面
      if (this.tr === '' || this.tr === undefined) {
        this.submit();
        return;
      }
      //执行修改
      this.modifyTopic();
    },
    //验证输入的信息
    requireForm() {
      this.formSuccess.topicName = !(this.form.topicName.trim().length > 10 || this.form.topicName.trim().length < 3);
      this.formSuccess.topicIntroduce = !(this.form.topicIntroduce.length <= 0);
      this.formSuccess.topicCredit = !(this.form.topicCredit === '')
      this.formSuccess.topicPeriod = !(this.form.topicPeriod > 30 || this.form.topicPeriod < 1)
      this.formSuccess.topicMaxPeople = !(this.form.topicMaxPeople > 15 || this.form.topicMaxPeople < 10)
      this.formSuccess.gradeRatio = !(this.form.gradeRatio === '')
      //count==6表示全部满足
      let count = 0;
      for (let i in this.formSuccess) {
        if (this.formSuccess[i] === true) {
          count++;
        }
      }
      return count;
    },
    //提交课题
    submit() {
      if (this.requireForm() !== 6) {
        startSuccessNotification("信息不合规！！！", "请输入正确的信息在提交！", 2)
        return;
      }

      axios.post("/api/topic", this.form,)
          .then(resp => {
            if (resp.data.code === 200) {
              startSuccessNotification("添加成功！！！");

            } else if (resp.data.code === 1005) startSuccessNotification("课题名已存在，请勿重复添加！！！", "", 1)
          })
          .catch(error => {
          })
          .finally(() => {
            endLoading()
          })
      //关闭窗口
      this.againActive = false;
    },
    //修改页面父组件传过来的tr 进行初始化赋值
    initModifyTopic(tr) {
      //当传过来的值不为空的时候 赋值
      if (tr === '' || tr === undefined) return;
      //需要将创建时间跟修改时间清空再传入后端,要不然后端无法接受到参数
      tr.topic.createTime = null;
      tr.topic.updateTime = null;
      this.form = tr.topic;
      this.title = '修改课题'
    },
    //修改课题
    modifyTopic() {
      startLoading()
      axios.put("/api/topic", this.form )
          .then(resp => {
            if (resp.data.code === 200) {
              startSuccessNotification("修改成功！！！");


            } else {
              startSuccessNotification("修改失败，未知错误！！！", "", 1)
            }
          })
          .catch(error => {
          })
          .finally(() => {
              endLoading()
            this.$emit('backEvent')
          })
      this.againActive = false;
    }

  }
};
</script>
<style lang="less" scoped>
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;

}

.form {
  box-sizing: border-box;
  width: 900px;
  height: 700px;
  padding: 15px 50px;

  //border: 1px solid red;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  .form-item {
    margin: 20px 0;
    width: 300px !important;
    max-width: 300px !important;
  }
}

.vs-input {
  width: 300px !important;
  max-width: 300px !important;
}
</style>