<script>
import axios from "axios";
import Cookies from "js-cookie";
import {startSuccessNotification} from "@/axios/loading";

export default {

  name: "Login",
  data:()=>{
    return{
      flag:1,
      selectRole:{
        identity: 'user',
        role:'学生'
      },
      i:1,
      changeRole:[
        '学生','教师'
      ],
        username:'',
        password:'',
      bgc:true,
    }
  },
  mounted() {
    setInterval(()=>{this.changeBg()} , 5000);
    window.addEventListener("keyup",(e)=>{
      if (e.key === 'Enter') {
        this.login(this.i)
      }
    })
  }
  ,
  methods: {
    login(i) {
      console.log(i)
      let url = '';
      if (i === 0) {
        url = 'user';

      }
      if (i === 1) {
        url = 'adm';
      }
      axios.post("/api/login/" + url, {'username': this.username, 'password': this.password}).then(resp => {
        if (resp.data.code === 200) {
          //将token存入本地
          localStorage.setItem("accessToken", resp.data.data[0]);
          localStorage.setItem("refreshToken",resp.data.data[1]);
         // Cookies.set('accessToken',resp.data.data[0],{expires:1,httponly:true});

          //设置菜单
          Cookies.set('menu',resp.data.data[2].menu, {expires:1});
          //设置身份
          Cookies.set("identity",(i===0? 'adm':'user'))
          this.$store.commit('nav/setMenu', resp.data.data[2].menu);
          //跳转首页
          //管理
          if (i === 1){
            this.$router.push({
              path: "/home"
            })
          }
          //普通用户
          else if (i === 0) {
            this.$router.push({
                path: "/student"
            })
          }
        } else {
          startSuccessNotification("账号或密码错误！！！", "请检查账户与密码是否输入正确，并确定是否选择正确的登录身份。", 2)
        }
      }).catch(error => {
        console.log(error)
      })
    }
    ,
    changeBg() {
      this.bgc = this.flag % 2 === 0;
      if (this.flag === 4)
        this.flag = 1;
      else
        this.flag++;
    }
  }
}
</script>

<template>
    <div >
      <div class="box" >
        <div class="content" :style="{backgroundImage:`url(${require('@/assets/login/bg'+flag+'.png')})`}">
          <div class="login-wrapper">
            <div class="mask"></div>
            <div class="login-content"  >
              <h1>登 录</h1>
              <div class="title-text">
                欢迎来到这个界面，您可以通过选择不同身份的登录到这个系统界面
              </div>
              <div class="other-login" :class="bgc? 'bgc-1':'bgc-2'">
                <img src="../assets/login/男学生.png" alt="">
                <span>当前<strong style="color:white;margin: 0 10px">{{changeRole[i&1]}}</strong>界面</span>
              </div>
              <div class="login-form">
                <input type="text" class="user" v-model="username" placeholder="账号">
                <input type="password" class="password" v-model="password" placeholder="密码">
              </div>
              <div>
                <button class="login-btn" @click="login(i)" >登 录</button>


              </div>
              <div style="display: flex;justify-content: right;margin-top: 10px"><button class="teacher-login" @click='i = 1-i' >{{changeRole[(1-i)&1]}}登录</button></div>

              <div class="tips">
                <span>还没有账号?</span>
                <span style="cursor: pointer">注册</span>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<style scoped lang="less">
.bgc-1{
 background-color:  rgb(248, 182, 217)
}
.bgc-2{
  background-color: rgb(182, 211, 248)
}
* {
  margin: 0;
  padding: 0;
}
.box {
  background-color: #fff;
  width: 100vw;
  height: 100vh;
  .content {
    width: 85vw;
    height: 90vh;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 20px;
    // background: url(./asset/bg4.png) no-repeat;
    object-fit: cover;
    transition: 0.5s;
    .login-wrapper {
      width: 500px;
      height: 100%;
      position: absolute;
      right: 0;
      box-sizing: border-box;
      .mask {
        width: 100%;
        height: 100%;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        position: absolute;
        background: rgba(255, 255, 255, 0.7);
        // box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.27);
        backdrop-filter: blur(15.5px);
      }
      .login-content {
        width: 80%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10;
        h1 {
          font-size: 45px;
          margin-bottom: 10px;
        }
        .title-text {
          color: rgb(65, 63, 63);
          margin-bottom: 50px;
        }
        .other-login {
          width: 100%;
          height: 50px;
          background-color: rgb(248, 182, 217);
          border-radius: 5px;
          transition: 1s;
          text-align: center;
          line-height: 50px;
          font-weight: 600;
          font-size: 20px;
          margin-bottom: 20px;
          color: rgb(41, 41, 41);
          cursor: pointer;
          img {
            width: 35px;
            height: 35px;
            vertical-align: middle;
          }
        }
        .login-form {
          margin-top: 40px;
          input {
            width: 100%;
            height: 45px;
            margin: 10px 0;
            border-radius: 5px;
            border: 0;
            padding: 10px;
            box-sizing: border-box;
            font-weight: 100;
            font-size: 20px;
            &:focus {
              outline: none;
            }
            &::placeholder {
              font-weight: 600;
              color: black;
            }
          }
        }
        .login-btn {
          width: 100%;
          height: 45px;
          margin-top: 25px;
          border-radius: 5px;
          border: 0;
          background-color: rgb(54, 59, 197);
          color: #fff;
          font-size: 22px;
          font-weight: 600;
          span:nth-child(2) {
            color: rgb(54, 59, 197);
            font-weight: 600;
          }
        }
      }
    }
  }
}
.teacher-login{
  border: none;
  color: #363BC5;
  font-size: 16px;
  border-bottom: 1px solid #363BC5;
  background: none;
}


</style>
