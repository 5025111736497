<script>
export default {
  name: "StudentStudyHome"
}
</script>

<template>
    <div>
      <router-view></router-view>
    </div>
</template>

<style scoped lang="less">

</style>