<template>
  <div class="center">
    <vs-table v-model="selected" striped>
      <template #header>
        <!--        <vs-input v-model="search" border placeholder="搜索学生 ..."/>-->
        <div style="display: flex;">

          <vs-button
              :active="active === false"
              style="margin: 0 20px 0 0;height: 40px;width: 80px"
              @click="isActive"
          >
            新增学生
          </vs-button>
          <!--  vs-select中的v-if 不加if条件选择之后框里没有字体而是空白，奇怪的bug-->
          <vs-select

              v-if="topicNames!==''"
              v-model="pageAndSelectTopic.selectTopic"
              label-placeholder="当前选择展示的课题"
              state="primary"
              style="margin-top: 10px"
          >
            <vs-option label="所有课题学生" value="0">
              所有课题学生
            </vs-option>
            <vs-option label="未选课题学生" :value="-1">
              未选课题学生
            </vs-option>
            <vs-option-group>
              <div slot="title" style="color:#c9c9cc">
                ————单个课题————
              </div>
              <vs-option v-for="e of  topicNames" :key="e.topicId" :label="e.topicName" :value="e.topicId">
                《{{ e.topicName }}》
              </vs-option>
            </vs-option-group>
          </vs-select>
        </div>

      </template>
      <template #notFound>
        <!--        没有找到相匹配的记录-->
        <el-empty :image-size="200" description="该课题没有学生"></el-empty>
      </template>
      <template #thead>

        <vs-tr>
          <vs-th style="max-width: 100px">
            序号
          </vs-th>
          <vs-th>
            学生姓名
          </vs-th>
          <vs-th>
            年龄
          </vs-th>
          <vs-th style="max-width: 220px">
            邮箱
          </vs-th>
          <vs-th sort @click="users = $vs.sortData($event, users, 'sex')">
            性别
          </vs-th>
          <vs-th sort @click="users = $vs.sortData($event, users, 'classId')">
            所在班级
          </vs-th>
          <vs-th>
            所选课题
          </vs-th>
          <vs-th @click="users = $vs.sortData($event, users, 'studentId')">
            操作
          </vs-th>
        </vs-tr>
      </template>
      <template #tbody>

        <vs-tr
            v-for="(tr, i) in $vs.getSearch(users, search)"
            :key="i"
            :data="tr"
            :is-selected="selected === tr"

        >
          <vs-td>
            {{ ++i }}
          </vs-td>
          <vs-td>
            {{ tr.name }}
          </vs-td>
          <vs-td>
            {{ tr.age }}
          </vs-td>
          <vs-td>
            {{ tr.email }}
          </vs-td>
          <vs-td>
            {{ tr.sex }}
          </vs-td>
          <vs-td>
            {{ tr.classId }}
          </vs-td>
          <vs-td>
            <div> <span v-if="tr.topic != null" style="color: #999999"> 《<b style="color: #000000">{{
                tr.topic.topicName
              }}</b>》 </span>
              <b v-else style="color:#FF4757">暂未选课题</b></div>

          </vs-td>

          <vs-td>
            <vs-button danger style="height: 40px" @click="outClass(tr)">
              退出课题
            </vs-button>
          </vs-td>

          <!--          <template #expand>-->
          <!--            <div style="display: flex;width: 100%">-->
          <!--              <vs-avatar>-->
          <!--                <img :src="tr.userPhoto" alt="">-->
          <!--              </vs-avatar>-->
          <!--              <p style="margin-left:20px"> 昵称： {{ tr.name }}</p>-->
          <!--            </div>-->
          <!--          </template>-->

        </vs-tr>


      </template>
      <template v-if="users.length!==0&&pages!==1" #footer>
        <vs-pagination v-model="pageAndSelectTopic.page" :length="pages" progress/>
      </template>
    </vs-table>

    <!-- 添加学生 -->
    <vs-dialog v-model="active" blur>
      <template #header>
        <h4>新增学生</h4>
      </template>
      <div class="add-student-center">
        <vs-input
            v-model="queryId"
            :loading="isLoading"
            :state="addState"
            label-placeholder="学生学号"
        >
          <template v-if="isNull" #message-danger>
            不能为空！！！
          </template>
        </vs-input>
        <vs-button style="margin: 20px" @click="getStudent ">
          查询学生
        </vs-button>
        <vs-button :disabled="isDisabled" flat style="margin: 20px" @click="addStudent">
          添加该学生
        </vs-button>
        <vs-dialog v-model="addActive">
          <div style="display: flex;flex-direction: column;align-items: center">
            <vs-select

                v-model="addStudentTopicId"
                label-placeholder="选择课题"
                state="primary"
                style="height: 60px;margin-top: 30px"
            >
              <template v-if="addStudentTopicMsg" #message-danger>
                请选择要添加的课题*
              </template>
              <vs-option v-for="e of  topicNames" :key="e.topicId" :label="e.topicName" :value="e.topicId">
                《{{ e.topicName }}》
              </vs-option>

            </vs-select>

            <div style="display: flex">
              <vs-button style="margin: 10px;width: 80px;height: 40px" transparent @click="addStudentAxios">
                添加
              </vs-button>
              <vs-button dark style="margin: 10px;width: 80px;height: 40px" transparent @click="addActive=false">
                取消
              </vs-button>
            </div>

          </div>

        </vs-dialog>

      </div>

      <template #footer>
        <div style="text-align: center">
          <h4>该学生信息</h4>
          <h4 v-if="queryUser===''">该学生不存在！！！</h4>
          <div v-else class="a">
            <vs-table>
              <template #thead>
                <vs-tr style="justify-content: center">
                  <vs-th> 学号</vs-th>
                  <vs-th> 姓名</vs-th>
                  <vs-th> 班级</vs-th>
                </vs-tr>
              </template>
              <template #tbody>
                <vs-tr>
                  <vs-td> {{ queryUser.studentId }}</vs-td>
                  <vs-td> {{ queryUser.name }}</vs-td>
                  <vs-td> {{ queryUser.classId }}</vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>
      </template>
    </vs-dialog>
    <!--    删除学生-->
    <AgainSubmit :active="active2"

                 @cancelActive="active2=false"
                 @submit="outClassAxios"
    >
      <template #title> 确认要将该学生：<b style="color: #FF4757">{{ selected.name }}</b> 退出所选的课题？
      </template>
    </AgainSubmit>

  </div>
</template>
<script>
import axios from "axios";
import {endLoading, startLoading, startSuccessNotification} from "@/axios/loading";
import AgainSubmit from "@/components/AgainSubmit.vue";


export default {
  components: {AgainSubmit},
  data: () => ({
    active2: false,
    active: false,
    //当前页跟选择展示哪个课题
    pageAndSelectTopic: {
      page: 1,   //第几页
      selectTopic: '0'  //0表示展示所有课题的学生
    },
    pages: 1,//页数
    isShowPage: true,
    search: "",
    selected: '',
    addState: "success",
    states: ["success", "danger"],
    queryId: "",
    addValue: "",
    isLoading: false,
    //数据,必须为[]
    users: [],
    queryUser: '',
    isNull: false,
    isDisabled: true,
    delLoading: false,
    selectTopic: '',
    // 教师教授的课题名字,数组
    topicNames: '',
    addActive: false,
    addStudentTopicId: '',
    //添加学生到课题时未选择课题时提示
    addStudentTopicMsg: false,
  }),


  watch: {
    pageAndSelectTopic: {
      immediate: true,
      deep: true,
      handler() {

        this.getStudentAllByPage()
      }

    },
    queryId(newVal) {
      if (newVal.trim() !== '') {
        this.isNull = false;
        this.addState = this.states[0]
      }
    },
  },
  created() {
    axios.get("/api/topic/names")
        .then(resp => {

          if (resp.data.code === 200) {
            this.topicNames = resp.data.data;
          }
        })
        .catch(error => {

        })
  },
  methods: {
    //查询学生信息分页显示
    getStudentAllByPage() {
      startLoading()
      axios.get("/api/student/page/" + this.pageAndSelectTopic.page + "/topic/" + this.pageAndSelectTopic.selectTopic)
          .then((resp) => {
            if (resp.data.code === 200) {
              this.isShowPage = true;
              //数据
              this.users = resp.data.data.records;
              //当前页
              this.pageAndSelectTopic.page = resp.data.data.current;
              //页数
              this.pages = resp.data.data.pages;
            } else {
              this.users = ''

            }

          }).catch((error) => {
        this.isShowPage = false;
        console.log(error);
      }).finally(() => {
        endLoading()
      })
    },
    //查询某个学生
    getStudent() {

      if (this.queryId.trim() === '') {
        this.isNull = true;
        this.addState = this.states[1]
        return
      }
      this.isLoading = !this.isLoading
      axios.get("/api/student/" + this.queryId).then(resp => {
        if (resp.data.code === 200) {
          this.queryUser = resp.data.data
          this.addValue = resp.data.data.studentId;
          this.isDisabled = false
        } else {
          this.addValue = ''
          this.queryUser = ''
          this.isDisabled = true
        }
        this.queryId = ''

        this.isLoading = !this.isLoading
      }).catch(error => {
        console.log(error)
        this.isLoading = !this.isLoading
      })
    },
    //是否激活添加
    isActive() {
      this.addActive = false;
      this.active = true;
      this.isDisabled = true
      this.addStudentTopicMsg = false
      this.addStudentTopicId = ''
      this.addValue = ''
      this.queryUser = ' ' //两个空格是为了第一次打开弹窗的时候，不展示 数据为空
    },
    addStudent() {

      this.addActive = true;

    },
    //添加学生
    addStudentAxios() {
      if (this.addStudentTopicId === '') {
        this.addStudentTopicMsg = true;
        return;
      } else this.addStudentTopicMsg = false;

      startLoading()
      axios.post("/api/student", {
        studentId: this.addValue,
        topicId: this.addStudentTopicId
      })
          .then(resp => {
            if (resp.data.code === 200) {
              startSuccessNotification("已成功添加！！！");
            } else startSuccessNotification(resp.data.msg, '请勿重复添加，如要修改，请退选后再重新添加。', 1)

          }).catch(error => {
        console.log(error)
      }).finally(() => {
        endLoading()
      })
      this.isDisabled = true
    },
    //退出班级
    outClass(tr) {
      if (tr.topic === undefined) {
        startSuccessNotification("该学生还未选课题！！！", "无法退出班级", 1)
        return
      }
      this.active2 = true
      this.selected = tr

    },
    outClassAxios() {

      let id = this.selected.studentId;
      this.delLoading = true;
      startLoading()
      axios.delete("/api/student/" + id).then(resp => {
        if (resp.data.code === 200) {
          startSuccessNotification("退出课题成功！！！");
          setTimeout(() => {
            history.go(0);
          }, 300)

        } else
          startSuccessNotification("退出课题失败！！！", resp.data.msg, 2)
      }).catch(error => {
        startSuccessNotification("退出课题失败！！！", "未知错误。", 2)

      }).finally(() => {
          endLoading()
        this.delLoading = false;
      })

    }
  }
};
</script>
<style lang="less" scoped>

.add-student-center {
  width: 500px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}


</style>

        