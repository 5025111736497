<template>
  <div class="center">
    <!--    列表-->
    <div v-if="show">
      <vs-table v-model="selected" striped>
        <template #header>
          <vs-select
              v-model="selectTopic.type"
              label="当前选择："
              state="primary"
              style="margin:15px 0 0 0; "
          >
            <vs-option label="发布的课题" value="publish">
              发布的课题
            </vs-option>
            <vs-option label="授课的课题" value="teach">
              授课的课题
            </vs-option>
          </vs-select>

        </template>
        <template #thead>
          <vs-tr>
            <vs-th style="max-width: 220px">
              编号
            </vs-th>
            <vs-th>
              课题名
            </vs-th>
            <vs-th>
              学分
            </vs-th>
            <vs-th>
              课时
            </vs-th>
            <vs-th>
              成绩占比
            </vs-th>
            <vs-th>
              最大容纳量
            </vs-th>
            <vs-th>
              当前班级人数
            </vs-th>
            <vs-th>
              授课老师
            </vs-th>
          </vs-tr>
        </template>
        <template #tbody>
          <vs-tr
              v-for="(tr, i) in topics"
              :key="i"
              :data="tr"
              :is-selected="selected === tr"
              style="height: 57px"
          >
            <vs-td>
              {{ i + 1 }}
            </vs-td>
            <vs-td>

              《<b style="color: #000000">{{ tr.topic.topicName }}</b>》

            </vs-td>
            <vs-td>
              {{ tr.topic.topicCredit }}
            </vs-td>
            <vs-td>
              {{ tr.topic.topicPeriod }}
            </vs-td>
            <vs-td>
              {{ tr.topic.gradeRatio }}
            </vs-td>

            <vs-td>
              {{ tr.topic.topicMaxPeople }}
            </vs-td>
            <vs-td style="color:red">
              {{ tr.topic.memberNum }}
            </vs-td>
            <vs-td>

              <span v-for=" (em,i) in tr.teacherNames"> {{ em }}</span>
            </vs-td>
            <template #expand>
              <div style="display: flex;justify-content: space-between">
                <span style="font-size: 13px; min-width: 60px; max-width: 500px;"> <b>简介：</b>
                  {{ tr.topic.topicIntroduce }}
                </span>
                <div
                    style="font-size: 12px;color:#999999;text-align: right;display: flex;flex-direction: column;justify-content: center;width: 200px; ">
                  <span>创建时间：<b>{{ tr.topic.createTime }}</b></span>
                  <span>上次修改时间：<b>{{ tr.topic.updateTime }}</b></span>
                </div>
                <span style="line-height: 42.67px;font-size: 12px;color:#999999">发布人(管理员)：<b>{{
                    tr.publisher
                  }}</b></span>
                <div class="con-content">
                  <vs-button v-show="selectTopic.type==='publish'" flat icon @click="modifyTopic(tr)">
                    修改课题
                  </vs-button>
                  <vs-button v-show="selectTopic.type==='publish'" flat icon @click="addActive=true">
                    邀请教师加入
                  </vs-button>
                  <vs-button v-show="selectTopic.type==='publish'" flat icon @click="delActive=true">
                    退出教师
                  </vs-button>
                  <vs-button v-show="selectTopic.type==='publish'" border danger @click="againActive=true">
                    删除
                  </vs-button>

                </div>
              </div>
              <el-divider>
              </el-divider>

            </template>

          </vs-tr>
        </template>
        <template #footer>
          <vs-pagination v-if="length!==1&&length!==0" v-model=" selectTopic.page" :length="length" not-margin/>
        </template>
        <template #notFound>
          <!--        没有找到相匹配的记录-->
          <el-empty :image-size="200"></el-empty>
        </template>

      </vs-table>
      <!--      删除课题弹窗-->
      <AgainSubmit :active="againActive"
                   @cancelActive="againActive=false"
                   @submit="deleteTopic(selected)"
      >
        <template #title><h4>确认要删除吗？？？</h4></template>
      </AgainSubmit>
      <!--      邀请教师课题弹窗-->
      <vs-dialog v-model="addActive">
        <template #header>
          <!--          加if的原因是  访问{{ selected.topic.topicName }} 第一次没有点击是不存在的,会报错 当点击了之后再渲染-->
          <h4 v-if="addActive">
            邀请教师加入课题：<b style="color: #195BFF">{{ selected.topic.topicName }}</b>
          </h4>
        </template>

        <div style="display: flex;justify-content: center">

          <vs-input v-model="teacherId" label-placeholder="教师编号"
                    type="number">
          </vs-input>

        </div>
        <template #footer>

          <vs-button :loading="isLoading" block @click="inviteAndQuitTeacherSubmit(1)">
            添加
          </vs-button>


        </template>
      </vs-dialog>

      <!--    退出教师-->
      <vs-dialog v-model="delActive">
        <template #header>
          <!--          加if的原因是  访问{{ selected.topic.topicName }} 第一次没有点击是不存在的,会控制台报错 当点击了之后再渲染-->
          <h4 v-if="delActive">
            在课题：<b style="color: #195BFF">{{ selected.topic.topicName }}</b>中退出教师
          </h4>
        </template>

        <div style="display: flex;justify-content: center">

          <vs-input v-model="teacherId" label-placeholder="教师编号"
                    type="number">
          </vs-input>

        </div>
        <template #footer>

          <vs-button :loading="isLoading" block @click=" inviteAndQuitTeacherSubmit(2)">
            添加
          </vs-button>


        </template>
      </vs-dialog>
    </div>

    <!--    修改-->
    <div v-else>
      <el-page-header class="back" content="修改课题" title="返回列表"
                      @back="show = !show;">
      </el-page-header>
      <add-topic :back="show" :tr="tr" @backEvent="show=!show"></add-topic>
    </div>

  </div>

</template>
<script>
import AgainSubmit from "@/components/AgainSubmit.vue";
import axios from "axios";
import {endLoading, startLoading, startSuccessNotification} from "@/axios/loading";
import AddTopic from "@/pages/AdmPage/addTopic.vue";

export default {
  components: {AgainSubmit, AddTopic},
  data: () => ({
    url:'http://localhost/login?from=%2F',
    show: true,
    //选中的那一行
    selected: null,
    //选择查看 发布的还是授课的 ,同时跟上第几页
    selectTopic: {
      type: 'publish',
      page: 1
    },
    //第几页
    page: 1,
    length: 1,
    againActive: false,
    tr: '213',
    //返回的数据
    topics: '',
    //邀请教师加入激活弹窗
    addActive: false,
    //教师id
    teacherId: '',
    //添加教师axios的加载
    isLoading: false,
    delActive: false,
  }),

  watch: {
    selectTopic: {
      immediate: true,
      deep: true,
      handler() {
        startLoading()
        axios.get("/api/topic/" + this.selectTopic.type + "/page/" + this.selectTopic.page)
            .then(resp => {
              if (resp.data.code === 200) {
                //数据
                this.topics = resp.data.data.records;
                //页数
                this.length = resp.data.data.pages;
                //当前页
                this.selectTopic.page = resp.data.data.current;
                //此处赋值不会再立刻监视到再次发一次请求
              }
            })
            .catch(error => {
              console.log(error)
            }).finally(() => {
          endLoading()
        })
      }
    },
  },
  mounted(){

  },
  methods: {

    //修改课题
    modifyTopic(tr) {
      this.show = false;

      //传给修改页面tr
      this.tr = tr
    },
    //删除课题
    deleteTopic(tr) {
      let id = tr.topic.topicId;
      this.againActive = false;
      startLoading()
      axios.delete("/api/topic/" + id,)
          .then(resp => {
            if (resp.data.code === 200) {
              startSuccessNotification("删除成功！！！");
              history.go(0)
            }
          })
          .catch(error => {
            console.log(error)
          }).finally(() => {
        endLoading()
      })

    },
    //邀请或者退出教师加入提交
    inviteAndQuitTeacherSubmit(index) {
      if (this.teacherId === '') {
        startSuccessNotification("编号不能为空", '', 1);
        return;
      }
      //添加
      if (index === 1) {
        axios.post("/api/teach", {teacherId: this.teacherId, topicId: this.selected.topic.topicId})
            .then(resp => {
              if (resp.data.code === 200) {
                startSuccessNotification("已添加该教师!!!");
                setTimeout(() => {
                  history.go(0)
                }, 500)

              } else startSuccessNotification(resp.data.msg, '', 1)
            })
            .catch(error => {

            })
      }
      //删除
      if (index === 2) {
        axios.delete("/api/teach/" + this.teacherId + "/topic/" + this.selected.topic.topicId)
            .then(resp => {
              if (resp.data.code === 200) {
                startSuccessNotification("已删除该教师!!!");
                setTimeout(() => {
                  history.go(0)
                }, 500)

              } else startSuccessNotification(resp.data.msg, '请检查教师编号是否正确', 1)
            })
            .catch(error => {

            })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.back {
  color: #195bff;

  :hover {
    color: #0045f6;
  }
}

.con-content {
  display: flex;
  justify-content: end;
}
</style>

