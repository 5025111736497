<template>
  <div>
    <el-page-header @back="goBack" content="我的周志" style="margin-bottom: 15px">
    </el-page-header>
    <div class="topicInfo">
      <div style="padding: 30px 0 0 20px">
        <h3 style="margin: 0 0 10px 0"> 参与项目：<span>{{ topicInfo.topic.topicName }}</span></h3>
        <div> 参与时间：<span>2023-06-05~2023-06-25</span></div>
        <div>参与形式：<span>集中安排</span></div>
      </div>
    </div>
    <div style="height: 700px;display: flex;flex-direction: column;justify-content: space-around">

      <div><span class="font-14"><b>* </b>标 题：</span>
        <el-input
            placeholder="请输入内容"
            show-word-limit
            maxlength="20"
            v-model="task.title"
            clearable
            style="width: 400px;">

        </el-input>
      </div>
      <div><span class="font-14"><b>* </b>时 间：</span>
        <el-date-picker
            style="width: 400px"
            v-model="task.date"
            type="week"
            format="yyyy 第 WW 周"
            value-format="yyyy/MM/dd HH:mm:ss"
            :picker-options="pickerOptions"
            placeholder="选择周期"

        >
        </el-date-picker>
      </div>


      <div style="display: flex">
        <span class="font-14"><b>* </b>内 容：</span>
        <div style="border: 1px solid #ccc;width: 80%">
          <Toolbar
              style="border-bottom: 1px solid #ccc;"
              :editor="editor"
              :defaultConfig="toolbarConfig"
              @onMaxLength="onMaxLength"
              :mode="mode"
          />
          <Editor
              style="height: 301px; overflow-y: hidden;"
              v-model="task.content"
              :defaultConfig="editorConfig"
              :mode="mode"
              @onCreated="onCreated"
          />
        </div>
      </div>
      <div class="buts" style="display: flex">
        <span class="font-14">其他附件：</span>
        <div style="height: 100px">
          <el-upload
              ref="upload"
              action=""
              :on-remove="handleRemove"
              :before-remove="beforeRemove"
              :limit="1"
              :on-exceed="handleExceed"
              :on-change="handleChange"
              :auto-upload="false"
              :file-list="fileList">
            <div class="buts">
              <button style="color: #195BFF" class="a">上传</button>
            </div>
            <div slot="tip" class="el-upload__tip" style="color:#F56C6C">只能上传.doc,.docx文件</div>
          </el-upload>
        </div>


      </div>
      <el-progress v-if="percentage!==0" :percentage="percentage" style="width: 200px;margin-left: 70px"
                   color="#409EFF"></el-progress>
      <div class="buts">
        <button @click="submit">发布</button>
        <button @click="draft">存入草稿箱</button>
      </div>
    </div>
    <!--  <CommLoader></CommLoader>-->

  </div>
</template>


<script>
import {Editor, Toolbar} from '@wangeditor/editor-for-vue'
import {toolbarConfig, editorConfig} from "@/config/editer"
import {endLoading, startLoading, startSuccessNotification} from "@/axios/loading";
import axios from "axios";
import Vue from "vue";
import { splitFile} from "@/utils/sectionFile"
import Cookies from "js-cookie";

export default {
  name: "WeeklyReport", components: { Editor, Toolbar},
  data: () => {
    return {
      editor: null,
      toolbarConfig: toolbarConfig,
      editorConfig: editorConfig,
      mode: 'simple', // or 'simple'
      pickerOptions: {
         firstDayOfWeek: 1,
        disabledDate: (date) => {
          // 获取当前日期
          let today = new Date();
          // 获取当前是星期几
          let day = today.getDay();
          // 计算本周第一天的日期
          let startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - day + 1);

          // 计算本周最后一天的日期
          let endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - day + 7);

          return !(date.getTime() <= endDate && date.getTime() >= startDate);
        },

  },
      //-------------------------------------------
      fileList: [],
      file: '',
      task: {
        title: '',
        date: '',
        content: '',  //html格式
      },
      percentage: 0,
      topicInfo: {
        topic: {topicName: ''}
      },
      teacherNames: ''
    }
  },
  watch:{
    task:{
      deep:true,
      handler(newVal){
        console.log(newVal.date)
        Cookies.set('draft',JSON.stringify(newVal), {expires:1/24 });
      }
    }
  },
  methods: {

    goBack() {
      this.$router.push({
        path: '/student/task'
      })

    },
    //草稿箱
    draft(){
      Cookies.set('draft',this.task);
    },
    initData() {
      //清空文件
      this.file = ''
      //this.$refs.upload.clearFiles();
      //清空文本数据
      Object.assign(this.task, {
        title: '',
        date: '',
        content: '',  //html格式
      });
    },
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错

    },
    onMaxLength(editor) {
      console.log(editor)
    },
    //======================================
    //添加时 ，设置file
    handleChange(file) {
      this.file = file.raw
      console.log(this.file)

    },
    //删除时，清空file
    handleRemove() {
      this.file = ''
    },

    handleExceed(files, fileList) {
      startSuccessNotification('已添加过文件！！！', `当前文件数量 ${files.length + fileList.length} 个文件，如要继续添加，请删除旧文件后再添加新的文件`, 1)
    },

    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },

    //=====================================
    rule() {
      let error = true
      for (const e in this.task) {
        if (this.task[e] === '') {
          error = false;
        }
        if (this.editor.getText() === '') {
          error = false;
        }
      }
      return error
    },
    //提交
    async submit() {
      if (!this.rule()) {
        startSuccessNotification('有未填项！！！', '标题,时间,内容不能为空，请填写之后再提交。', 1)
        return
      }
      if (await splitFile(this.file) === false) {
        this.upLoadWeeklyReportAxios();

      } else
        await this.upLoadPart()
      this.goBack();
    },
    //上传axios
    upLoadWeeklyReportAxios() {
      let data = {
        title: this.task.title,
        content: this.task.content,
        associatedWeek: this.task.date,
      }

      let fromData = new FormData()
      startLoading("正在上传中...")
      //文件存在，并且不需要分片
     splitFile(this.file).then((e)=>{
        if (e == false) {
          fromData.append('file', this.file)
        }
        console.log(1)
      })
      //else if(this.file!==''&&splitFile(this.file)!==false) fromData.append('cacheFile')

      fromData.append('weeklyReport', JSON.stringify(data))

      axios.post("/api/task", fromData, {
        headers: {isFile: true}, onUploadProgress(ev) {
          //Vue.prototype.myLoad 就是全局唯一的加载动画 ，最多为99%
          Vue.prototype.myLoad.changePercent(Math.floor(ev.loaded / ev.total * 100 - 1) + '%')
        }
      })
          .then(resp => {
            // 当返回数据的时候财位100
            Vue.prototype.myLoad.changePercent(100 + '%')
            if (resp.data.code === 200) {

              startSuccessNotification("添加成功！！！")

            } else
              startSuccessNotification(resp.data.msg, '', 1)

          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            endLoading()
            this.initData()

          })

    },
    //上传-多文件
    async upLoadPart() {
      //先分片
      let part = await splitFile(this.file)
      if (part === false) {
        return;
      }

      let weeklyReport = {
        title: this.task.title,
        content: this.task.content,
        associatedWeek: this.task.date,

      }
      let flag = false
      await axios.post("/api/task/upload/ack", {...weeklyReport, ...part.fileInfo}).then(resp => {
        if (resp.data.code === 200) {
          flag = true
        } else
          startSuccessNotification(resp.data.msg, '', 1)

      }).catch(error => {
        console.log(error)
      })
      if (flag === false) {
        this.initData();
        return
      }

      //总大小
      let countSize = this.file.size;
      //已上传
      let loaded = 0;

      startLoading(" 正在上传中...")
      for (const item of part.cache) {
        let fromData = new FormData()
        //文件
        fromData.append("file", item.partFile, item.fileCodeName);
        //总片数
        fromData.append("chunks", item.chunks);
        //当前片数
        fromData.append("chunk", item.chunk);
        await axios.post("/api/task/upload", fromData, {
          headers: {isFile: true},
          onUploadProgress(ev) {
            loaded += ev.loaded;
          }
        })
            .then(resp => {
            })
            .catch(error => {
              flag = false
              console.log(error)
            }).finally(() => {

            })

        this.percentage = Math.floor(loaded / countSize * 100) >= 100 ? 100 : Math.floor(loaded / countSize * 100)
        Vue.prototype.myLoad.changePercent(this.percentage + "%");
      }
      if (flag) startSuccessNotification("保存成功！！！");
      else startSuccessNotification("保存失败！！！", "内部错误请稍后重试。", 1)
      endLoading()
      this.initData();

    },

  },

  created() {
    startLoading()
    axios.get("/api/student/topic",).then(resp => {
      if (resp.data.code === 200) {
        this.topicInfo = resp.data.data
        this.teacherNames = resp.data.data.teacherNames
      }
    }).catch(error => {
      console.log(error)
    }).finally(() => {
      endLoading()
    })
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },
  mounted() {
    //草稿箱

    if (Cookies.get('draft') !== undefined) {
      this.task =JSON.parse(Cookies.get('draft'))
    }

  },

}
</script>

<style scoped lang="less">
.topicInfo {
  width: 90%;
  height: 120px;
  font-size: 12px;
  background-image: linear-gradient(0, #ffffff 0%, #F4F6FD 100%);
}

.buts {
  > button {
    width: 120px;
    height: 40px;
    border: 1px solid #409EFF;
    background-color: white;
    border-radius: 5px;
    margin: 0 10px;

    &:hover {
      transition: all 0.4s;
      box-shadow: 0px 0px 8px 0px #195BFF;
    }
  }
}

.font-14 {
  font-size: 14px;
  color: #409EFF;

  > b {
    color: #F56C6C;
  }
}

</style>