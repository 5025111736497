import { render, staticRenderFns } from "./StudentScore.vue?vue&type=template&id=27c5f37c&scoped=true&"
import script from "./StudentScore.vue?vue&type=script&lang=js&"
export * from "./StudentScore.vue?vue&type=script&lang=js&"
import style0 from "./StudentScore.vue?vue&type=style&index=0&id=27c5f37c&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27c5f37c",
  null
  
)

export default component.exports