import VueRouter from 'vue-router';
import Home from '../pages/Home';
import StudentList from '@/pages/AdmPage/StudentList';
import StudentScore from '@/pages/AdmPage/StudentScore';
import StudentStudyInfo from '@/pages/AdmPage/studentStudy/StudentStudyInfo.vue';
import addTopic from '@/pages/AdmPage/addTopic.vue';
import Login from "../pages/Login.vue";
import TopicInfo from "@/pages/AdmPage/TopicInfo.vue";
import Task from "@/pages/UserPage/Task.vue";
import StudentScoreInfo from "@/pages/UserPage/StudentScore.vue";
import TopicReport from "@/pages/UserPage/TopicReport.vue";
import WeeklyReport from "@/pages/UserPage/WeeklyReport.vue";
import WeeklyReportList from "@/pages/UserPage/weeklyRepoty-list/WeeklyReportList.vue";
import OneTaskInfo from "@/pages/UserPage/weeklyRepoty-list/ShowTaskInfo.vue";
import WeeklyReportLIstHome from "@/pages/UserPage/weeklyRepoty-list/WeeklyReportLIstHome.vue";
import StudentStudyHome from "@/pages/AdmPage/studentStudy/StudentStudyHome.vue";
import axios from "axios";
import Jenkins from "@/pages/UserPage/Jenkins.vue";


let originPush = VueRouter.prototype.push;  //备份原push方法

VueRouter.prototype.push = function (location, resolve, reject) {
    if (resolve && reject) {    //如果传了回调函数，直接使用
        originPush.call(this, location, resolve, reject);
    } else {                     //如果没有传回调函数，手动添加
        originPush.call(this, location, () => {
        }, () => {
        });
    }
}

const admRouter = new VueRouter({
    routes: [
        //根目录
        {
            path: '/',
            redirect: '/home'
        },
        //管理员页面
        {
            name: 'home',
            path: "/home",
            meta: {title: '首页'},
            component: Home,
            redirect: "home/stulist",
            children: [
                // //首页面
                // {
                //     name: 'index',
                //     path: 'index',
                //     component: Index
                // },

                {
                    name: 'studentlist',
                    path: 'stulist',
                    meta: {title: '班级学生管理'},
                    component: StudentList
                },
                //用户管理

                //二级菜单


                {
                    name: 'taskManagement',
                    path: 'topic/task',
                    meta: {title: '课题管理'},
                    component: TopicInfo,

                },
                {
                    name: 'addTopic',
                    path: "topic/addtopic",
                    meta: {title: '添加课题'},
                    component: addTopic
                },
                {
                    name: 'studyHome',
                    path: 'topic/study',
                    meta: {title: '学生周报'},
                    component: StudentStudyHome,
                    redirect: "/home/topic/study/list",
                    children: [
                        {
                            name: 'study',
                            path: 'list',
                            meta: {title: '学生周报详情'},
                            component: StudentStudyInfo
                        },
                        {
                            name: 'showTaskInfoTeacher',
                            path: 'show/:id',
                            meta: {title: '查看'},
                            component: OneTaskInfo
                        }

                    ]
                },
                {
                    name: 'score',
                    path: 'topic/score',
                    meta: {title: '学生成绩信息'},
                    component: StudentScore
                },

                // {
                //     name: 'stufile',
                //     path: 'topic/stufile',
                //     meta: {title: '学生学习材料管理'},
                //     component: StudentFile
                // }


            ]
        },

        //学生页面
        {
            name: 'student',
            path: "/student",
            meta: {title: '首页'},
            component: Home,
            redirect: '/student/task',
            children: [

                // {
                //     name: 'studentIndex',
                //     path: 'index',
                //     component: StudentIndex
                // },
                {
                    name: 'task',
                    path: 'task',
                    meta: {title: '课题任务'},
                    component: Task,
                },

                {
                    name: 'weeklyReport',
                    path: 'weeklyreport',
                    meta: {title: '我的周志'},
                    component: WeeklyReport

                },
                {
                    name: 'weeklyReportListAll',
                    path: 'weeklyreportlist',
                    meta: {title: '周志'},
                    component: WeeklyReportLIstHome,
                    redirect: '/student/weeklyreportlist/list',
                    children: [
                        {
                            name: 'weeklyReportList',
                            path: 'list',
                            meta: {title: '列表'},
                            component: WeeklyReportList,
                        },
                        {
                            name: 'showTaskInfo',
                            path: 'show/:id',
                            meta: {title: '查看'},
                            component: OneTaskInfo
                        }
                    ]
                },

                // {
                //     name: 'weeklyReportList',
                //     path: 'weeklyreportlist',
                //     meta: {title: '周志'},
                //     component: WeeklyReportList,
                //     children:[
                //         {
                //             name:'oneTaskInfo',
                //             path:'onetaskinfo',
                //             component:OneTaskInfo
                //         }
                //     ]
                // },
                {
                    name: 'internshipReport',
                    path: 'internshipreport',
                    meta: {title: '课题报告'},
                    component: TopicReport
                },
                {
                    name: 'scoreInfo',
                    path: 'scoreinfo',
                    meta: {title: '实习成绩'},
                    component: StudentScoreInfo
                }

            ]
        },
        //登录页面
        {
            name: 'login',
            path: '/login',
            component: Login
        }


    ]
})
admRouter.beforeEach((to, from, next) => {
    let token = localStorage.getItem("accessToken");
    //let token = Vue.prototype.$cookie.get("token");
    //没登录就跳转登录页面
    if (!token && to.fullPath !== '/login') {
        next("/login")
    }
    //如果token存在并且是前往登录界面，则阻止进入登录页面
    if (token && to.fullPath === '/login') {
        next(from.fullPath)
    }

//     axios.request({
//         url: '/jenkins/j_spring_security_check',
//         method: 'post',
//         data: {
//             j_username: 'admin',
//             j_password: 'chanjet1987',
//             from:'/',
//             remember_me: 'on'
//
//
// },
//     }).then((resp) => {
//         console.log(resp)
//
//     })
    if (from.fullPath.startsWith("/home") && to.fullPath.startsWith("/student")) {
        // startSuccessNotification("无权访问！！！",'如要访问请退出登录并选择正确方法登录。',2)
        next("/")
        return
    }
    if (from.fullPath.startsWith("/student") && to.fullPath.startsWith("/home")) {
        // startSuccessNotification("无权访问！！！",'如要访问请退出登录并选择正确方法登录。',2)
        next("/student")
        return
    }
    next()

})
export default admRouter;
