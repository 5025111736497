<template>
  <div>
    <div class="content">
      <div>
        <h4 style="margin: 10px 0 20px 0">我的周志</h4>
        <div style="margin: 10px 0;display: flex;justify-content: space-between">
          <vs-select placeholder="选择课题" v-model="task.topicId" v-if="task!==''">
            <vs-option :label="'当前课题：'+task.topicName" :value="task.topicId">
              {{ task.topicName }}
            </vs-option>

          </vs-select>
<!--          <button class="downLoad"  :disabled="tableData.length===0">下载</button>-->
        </div>
        <p style="font-size: 12px;background-color: #FFFBDD;letter-spacing: 2px;border-radius: 5px;padding: 10px ">
          所有的周志都是不能补交的，请及时在本周之内完成提交，过期则无法记录。<br>
          周报如要进行修改请删除之后再重新发布，编辑功能暂时未实现！！！
        </p>
      </div>
      <div>
        <el-table
            :data="tableData"

            style="width: 100%"
            :border="true"
            :header-cell-style="{
      backgroundColor:'#EBF1FA' ,color:'#000000'}"
        >

          <el-table-column
              label="标题"
          >
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.title }}</span>
            </template>
          </el-table-column>
          <el-table-column
              label="关联日期"
          >
            <template slot-scope="scope">
              <i class="el-icon-time"></i>
              <span style="margin-left: 10px">{{ scope.row.associatedWeek }}</span>
            </template>
          </el-table-column>
          <el-table-column
              label="参与课题项目"
          >
            <template>
              <el-tag size="medium">{{ task.topicName }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                  size="mini"
                  @click="handleEdit(scope.row.weeklyReportId, scope.row)">查看
              </el-button>

              <el-button
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope.row.weeklyReportId, scope.row)">删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <AgainSubmit title="确定要删除吗？？？" :active="active" @cancelActive="active=false"
                     @submit="delWeeklyReport"></AgainSubmit>
      </div>

    </div>
  </div>

</template>


<script>
import axios from "axios";
import AgainSubmit from "@/components/AgainSubmit.vue";
import {endLoading, startLoading, startSuccessNotification} from "@/axios/loading";

export default {

  name: "WeeklyReportList",
  components: {AgainSubmit},

  data: () => {
    return {
      active: false,
      task: '',
      tableData: [],
      deleteId: '',
    }
  },
  methods: {
     async init(){
       startLoading()
       await axios.get("/api/student/topic").then(resp => {
          if (resp.data.code === 200) {
            this.task = resp.data.data.topic
          } else
            this.task = ''
        }).catch(error => {
          console.log(error)
        })
       await axios.get("/api/task").then(resp => {
          console.log(resp.data)
          if (resp.data.code === 200) {
            this.tableData = resp.data.data
          }
        }).catch(error => {
          console.log(error)
        })
       endLoading()
      },
    delWeeklyReport() {
      axios.delete("/api/task/" + this.deleteId)
          .then(resp => {
            if (resp.data.code === 200) {
              startSuccessNotification("删除成功！！！");
              this.active = false
              this.$router.go(0)
            }
            console.log(resp)
          })
          .catch(error => {
            console.log(error)
          })
    },
    handleEdit(index, row) {
      this.$router.push({
        name: 'showTaskInfo',
        params: {
          id: index,
        }
      })
      console.log(index)
      console.log(row)
    },
    handleDelete(index, row) {
      //激活弹窗
      this.active = true
      this.deleteId = index
    }
  },
  created () {
    this.init()
  }
}
</script>
<style scoped lang="less">

</style>