export default {
    namespaced: true,
    state: {
        //管理员与普通员不一样侧栏
        pageInfo: []
    },

    mutations: {
        //第一次的时候进行初始化设置并放到sessionStorage中
        setMenu(state, val) {
            state.pageInfo = JSON.parse(val);

        },
    },

}