<template>
  <div>

    <el-page-header @back="goBack" content="查看周报信息">

    </el-page-header>
    <el-divider></el-divider>
    <div style=" background-color: #F9F9F9; padding: 20px;">
      <div>


        <button class="downLoad"  :disabled="!fileExists" @click="download"> 下载附件</button>

        <h5 style="text-align: center" >标题：{{data.title}}</h5>
        <div class="info">

          <span>创建时间：{{data.createTime}}</span>
          <span>关联日期：{{data.associatedWeek}}~{{data.associatedWeek}}</span>
        </div>
      </div>
      <hr style="background-color: #DDDDDD;border: none;height: 1px">
      <div class="content" v-if="data!==''">

        <Editor
            style="height: 300px"
            v-model="html"
            :defaultConfig="editorConfig"
            :mode="mode"
            @onCreated="onCreated"
        />

      </div>

    </div>

  </div>
</template>


<script>

import {Editor, Toolbar} from '@wangeditor/editor-for-vue'
import axios from "axios";
import {startLoading, startSuccessNotification} from "@/axios/loading";

import downloadAxios from "@/axios/downloadFileAxios";

export default {
  name: "ShowTaskInfo",
  components: {Editor, Toolbar},
  data: () => {
    return {
      //周报课题数据
      data: '',
      id: '',
      editor: null,
      html: '',
      toolbarConfig: {},
      editorConfig: {
        placeholder: '...',
        readOnly: true,

      },
      mode: 'default', // or 'simple'
      fileExists:false,
    }
  },
  mounted() {
    this.id = this.$route.params.id;

    axios.get("/api/task/" + this.id).then(resp => {
      if (resp.data.code === 200) {
        this.data = resp.data.data.weeklyReport;
        //处理
        resp.data.data.fileExists===0?  resp.data.data.fileExists='false': this.fileExists='true';
      } else startSuccessNotification( '未知错误！！！',resp.data.msg, 1)
    }).catch(error => {
      console.log(error)
    })

  },

  methods: {
    startSuccessNotification,
    startLoading,
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
      this.editor.setHtml(this.data.content)
    },
    goBack() {

      this.$router.go(-1)
    },
    download(){
      //1表示下载周报附件
      downloadAxios(1,this.id)
    },
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  }
}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped lang="less">

.info {
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: #999999;

  > span {

    padding: 0 10px;
  }
}

.content {
  font-size: 14px;
}

</style>