<template>
  <div class="header">
    <div ref="info">
      <el-breadcrumb separator="/"   >
        <el-breadcrumb-item
            v-for="e in this.$route.matched"
            :key="e.path"
            :to="{ path: e.path }"

        >{{ e.meta.title }}
        </el-breadcrumb-item>

      </el-breadcrumb>
    </div>
    <div style="flex: 3;line-height: 60px;display: flex">
      <div class="aaa">
        <i class='bx bxs-dog bx-tada' style="font-size: 20px"></i>
      </div>

    </div>
    <div style="display: flex">
      <h5 v-if="user!==''">欢迎回来，<b style="color: #195bff;cursor: pointer" @click="dialogVisible=true">{{
          user.name
        }}</b>！</h5>
      <el-dropdown trigger="click" @command="handleCommand">
        <span class="el-dropdown-link">
          <el-avatar :size="50" :src="circleUrl" class="userPhoto"></el-avatar>
        </span>
        <el-dropdown-menu slot="dropdown" class="user-drop-down">
          <el-dropdown-item command="0">修改头像</el-dropdown-item>
          <el-dropdown-item command='1'>个人中心</el-dropdown-item>
          <el-dropdown-item command="2">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <vs-dialog v-model="dialogVisible" woverflow-hidden>
      <div>

        <div class="a">
          <h2 style="text-align: center;margin-bottom: 0px">我的个人信息
          </h2>
          <div>
            <el-upload

                :show-file-list="false"
                action="https://jsonplaceholder.typicode.com/posts/"
                class="avatar-uploader"
                style="width: 60px;height: 60px">
              <img v-if="imageUrl" :src="imageUrl" class="photo" style="width: 100%;height: 100%;">

            </el-upload>
          </div>
          <div>
            <vs-input v-model="user.name" disabled label-placeholder="姓名" success>
              <template #icon>
                <box-icon class="box-icon" name="user"></box-icon>
              </template>
            </vs-input>

          </div>
          <div>
            <vs-input v-model="user.age" disabled label-placeholder="年龄">
              <template #icon>
                <box-icon class="box-icon" name="calendar"></box-icon>
              </template>

            </vs-input>
          </div>
          <div>
            <vs-input v-model="user.sex" disabled label-placeholder="性别">
              <template #icon>
                <box-icon class="box-icon" name="male-sign"></box-icon>
              </template>
            </vs-input>
          </div>

          <div>
            <vs-input disabled label-placeholder="密码"
                      type="password"
                      value="********">
              <template #icon>
                <box-icon class="box-icon" name="lock-alt"></box-icon>
              </template>
            </vs-input>
            <vs-button
                :active="active === 1"

                @click="updateInfo(1)"
            >修改
            </vs-button>
          </div>

          <div>
            <vs-input v-model="user.nickname" disabled label-placeholder="昵称">
              <template #icon>
                <box-icon class="box-icon" name="user-pin"></box-icon>
              </template>
            </vs-input>
            <vs-button
                :active="active == 0"

                @click="updateInfo(2)"
            >修改
            </vs-button>
          </div>

          <div>
            <vs-input v-model="user.username" disabled label-placeholder="用户名">
              <template #icon>
                <box-icon class="box-icon" name="id-card"></box-icon>
              </template>
            </vs-input>
          </div>

          <div>
            <vs-input v-model="user.email" disabled label-placeholder="邮箱">
              <template #icon>
                <box-icon class="box-icon" name='envelope'></box-icon>
              </template>
            </vs-input>
            <vs-button
                :active="active == 0"
                @click="updateInfo(3)"
            >修改
            </vs-button>

          </div>

        </div>
      </div>
    </vs-dialog>
    <vs-dialog v-model="updateVisible" prevent-close width="300px">

      <div v-if="isShow===1" class="u1">

        <h4 style="text-align: center">
          修改密码
        </h4>
        <vs-input
            v-model="pswValue"
            :progress="getProgress"
            :visiblePassword="hasVisiblePassword"
            icon-after
            label-placeholder="请输入密码"
            type="password"
            @click-icon="hasVisiblePassword = !hasVisiblePassword">

          <template #icon>
            <box-icon v-if="!hasVisiblePassword" name='show'></box-icon>
            <box-icon v-else name='hide'></box-icon>
          </template>
          <template v-if="getProgress >= 100&&validPassword" #message-success>
            健壮的
          </template>
          <template v-if="!validPassword&&pswValue!==''" #message-danger>
            密码不合法
          </template>
        </vs-input>
        <vs-input v-model="pswValue2" placeholder="确认密码" primary style="margin: 20px 0" type="password">
          <template v-if="!againPassword&&pswValue2!=''" #message-danger>
            两次密码不一致
          </template>
        </vs-input>
        <vs-button style="width: 80px;margin: 0 auto" @click="updateAxios">确定修改</vs-button>
      </div>
      <div v-if="isShow===2" class="u2">
        <h4 style="text-align: center">
          修改昵称
        </h4>
        <vs-input v-model="nicknameValue" label-placeholder="请输入昵称" primary style="margin: 20px 0"></vs-input>
        <vs-button style="width: 80px;margin: 0 auto" @click="updateNicknameAndEmailAxios(isShow)">确定修改</vs-button>
      </div>
      <div v-if="isShow===3" class="u3">
        <h4 style="text-align: center">
          解绑并更新邮箱
        </h4>
        <vs-input v-model="emailValue" placeholder="请输入邮箱 " style="margin: 20px 0">
          <template #icon>
            <box-icon name='envelope' style="width: 20px;height: 20px;"></box-icon>

          </template>
          <template v-if="validEmail" #message-success>
            有效的邮箱
          </template>
          <template v-if="!validEmail && emailValue !== ''" #message-danger>
            无效的邮箱
          </template>
        </vs-input>
        <vs-button style="width: 80px;margin: 0 auto" @click="updateNicknameAndEmailAxios(isShow)">确定修改</vs-button>
      </div>
    </vs-dialog>
    <vs-dialog v-model="active3" not-center width="200px">
      <template #header>
        <h4 style="text-align: center">
          确认退出系统吗？？？
        </h4>
      </template>

      <template #footer>
        <div style="display: flex;justify-content: right">
          <vs-button color="danger" size="large" transparent @click="exit">
            确定
          </vs-button>
          <vs-button dark size="large" transparent @click="active3=false">
            取消
          </vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>

</template>

<script>

import axios from "axios";
import {startSuccessNotification} from "@/axios/loading";

export default {

  name: "CommHeader",
  data() {
    return {
      pswValue: '',
      pswValue2: '',
      nicknameValue: '',
      emailValue: '',
      hasVisiblePassword: false,
      isShow: 0,
      isDisabled: true,
      updateVisible: false,
      user: '',
      active: 0,
      circleUrl: require("../assets/user.png"),
      dialogVisible: false,
      active3: false,
      imageUrl: require("../assets/user.png"),
    };
  },
  computed: {
    getProgress() {
      let progress = 0

      // at least one number

      if (/\d/.test(this.pswValue)) {
        progress += 20
      }

      // at least one capital letter

      if (/(.*[A-Z].*)/.test(this.pswValue)) {
        progress += 20
      }

      // at menons a lowercase

      if (/(.*[a-z].*)/.test(this.pswValue)) {
        progress += 20
      }

      // more than 5 digits

      if (this.pswValue.length >= 6) {
        progress += 20
      }

      // at least one special character

      if (/[^A-Za-z0-9]/.test(this.pswValue)) {
        progress += 20
      }

      return progress
    },
    validPassword() {
      //^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[._~!@#$^&*])[A-Za-z0-9._~!@#$^&*]{8,20}$
      return /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z\W]{6,18}$/.test(this.pswValue)
    },
    validEmail() {
      return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.emailValue)
    },
    againPassword() {
      //密码合法，并且两次都对 则为true
      return this.validPassword && (this.pswValue === this.pswValue2)
    }
  },

  mounted() {
    this.clearDataInfo();
    //查看当前登录用户信息
    axios.get("/api/user").then((resp) => {
      if (resp.data.code === 200) {
        this.user = resp.data.data;
      }
    }).catch((error) => {
      console.log(error)
    })
  },
  methods: {
    exit() {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
      startSuccessNotification("退出成功",)
      this.$router.push({
        path: '/login'
      })
    },
    clearDataInfo() {
      this.pswValue2 = "";
      this.pswValue = "";
      this.nicknameValue = "";
      this.emailValue = "";
    },
    handleCommand(command) {
      //个人中心
      if (command === '1') {
        this.dialogVisible = true;
      }
      //退出系统
      else if (command === '2') {
        this.active3 = !this.active3;

      }
    },

    updateInfo(i) {
      //展示第几个 ，一共有三个 ，第一个为修改密码 ，第二个为修改昵称，第三个为邮箱
      this.isShow = i;
      this.updateVisible = !this.updateVisible;
      this.clearDataInfo()
    },
    updateAxios() {
      //密码合法则发送请求
      if (this.againPassword) {

        let password = this.pswValue.trim()
        axios.put("/api/user/psw", {info: password, type: this.isShow}).then(resp => {
          if (resp.data.code === 200) {
            startSuccessNotification('修改成功！！！', '跳转到登录界面，请重新登录')

            this.clearDataInfo()
            //跳转登录界面

            this.$router.push({
              path: '/login'
            })

          }

        }).catch(error => {
          console.log(error)
        })
      } else
        this.$vs.notification({
          duration: 2000,
          position: 'top-center',
          color: 'warn',
          title: '输入正确信息后再提交修改！！！',

        })
      this.updateVisible = false;
    },
    updateNicknameAndEmailAxios(type) {
      let info = '';
      if (type === 2) info = this.nicknameValue.trim();
      if (type === 3) info = this.emailValue;

      //如果邮箱不合法 或者info为空则返回
      if ((!this.validEmail && type === 3) || info === '') {
        this.$vs.notification({
          duration: 2000,
          position: 'top-center',
          color: 'warn',
          title: '输入正确信息后再提交修改！！！',
        })
        return;
      }
      axios.put("/api/user/psw", {type: type, info: info}).then(resp => {
        if (resp.data.code === 200) {
          this.$vs.notification({
            duration: 3000,
            position: 'top-center',
            color: 'success',
            title: '修改成功！！',
            text: ""
          })

        }
      }).catch(error => {
        console.log(error)
      })
      this.updateVisible = false;
    }
  },

};
</script>

<style lang="less" scoped>
.aaa {

  animation-name: k1;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

@keyframes k1 {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(50vw);
  }
}

.a {
  width: 600px;
  height: 600px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-around;

  > div {
    display: flex;
  }
}

.el-dropdown-menu__item:not(.is-disabled):hover {
  color: #195bff;
}

.header {
  display: flex;
  justify-content: space-between;
  box-shadow: 1px 0px 20px #efeded;
  border-radius: 10px;

  > div {
    height: 60px;
    // background-color: aqua;
  }

  > div:nth-of-type(1) {
    display: flex;
    align-items: center;
    padding-left: 10px;
  }
}

.userPhoto {
  margin: 5px;
}

.user-drop-down {
  border-radius: 10px;
}

.u1, .u2, .u3 {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.u1 {
  display: flex;
  justify-content: center;
  flex-direction: column;
}


.avatar-uploader {

  margin: 0 auto;
  border-radius: 30px;
  overflow: hidden;
  box-shadow: 0 0 10px 0 #195BFF;


}

.photo {
  background-color: rgba(0, 0, 0, 0.5);

}
</style>