<template>
  <div>
    <h4 style="margin-top: 0">学生周报信息情况</h4>
    <hr style="background-color:#EBEEF5; border:none; height:1px;margin-bottom: 10px">
    <div>
      <vs-select placeholder="选择课题" v-model="topicId" v-if="topicList!==''">
        <vs-option :label="'当前展示的课题：'+item.topicName" :value="item.topicId" v-for="(item,i) in topicList"
                   :key="i">
          《{{ item.topicName }}》
        </vs-option>
      </vs-select>
    </div>
    <div class="search" style="display: flex;margin-top:10px ">

      <el-date-picker
          style="margin: 10px 0 10px 0"

          size="small"
          v-model="search.date"
          value-format="yyyy/MM/dd HH:mm:ss"
          type="daterange"
          align="left"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
      >
      </el-date-picker>

      <vs-input state="primary" primary v-model="search.name" placeholder="请输入学生名称" style="margin-left: 15px">
        <template #icon>
          <i class='bx bx-search'></i>
        </template>
      </vs-input>


      <vs-button
          style="height: 33px;margin-top: 8px"
          border
          @click="searchAxios(1)"
      >
        搜索
      </vs-button>


    </div>
    <div style="display: flex;flex-direction: column;align-items: center">
      <el-table
          empty-text="本周暂无数据"
          :data="tableData"
          :header-cell-style="{backgroundColor:'#eff4f6',padding:'5px 0'}"
          :row-class-name="tableRowClassName"
          style="width: 100%">
        <el-table-column
            type="index"
            label="编号"
            width="180"
            >
        </el-table-column>

        <el-table-column
            prop="student.name"
            label="姓名"
            >
        </el-table-column>
        <el-table-column

            label="提交时间"
        >
          <template slot-scope="scope">
            <i class="el-icon-time"></i>
            <span style="margin-left: 10px">{{ scope.row.weeklyReportDTO.weeklyReport.createTime }}</span>
          </template>

        </el-table-column>
        <el-table-column

            label="附件">
          <template slot-scope="scope">
            <el-tag type="success"
                    size="small"
                    style="cursor: pointer" effect="dark"
                    @click="download(scope.$index, scope.row)"
                    v-if=" scope.row.weeklyReportDTO.fileExists>0">
              下载
            </el-tag>
            <el-tag type="info" size="small" effect="dark" v-else>&nbsp;&nbsp;无&nbsp;&nbsp;</el-tag>


          </template>
        </el-table-column>

        <el-table-column
            prop="student.name"
            label="操作"

        >
          <template slot="header">
            <el-tag>本周提交人数：{{ submitNum.current}}/{{submitNum.total}}</el-tag>
          </template>
          <template slot-scope="scope">
            <el-button
                size="mini"
                type="primary"
                plain
                @click="handleEdit(scope.$index, scope.row)">查看周报详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin: 20px 0 0 0">
        <el-pagination
            background
            layout="prev, pager, next"
            :current-page="currentPage"
            :hide-on-single-page="true"
            :total="page">
        </el-pagination>
      </div>

    </div>
  </div>
</template>

<script>
import axios from "axios";
import {startSuccessNotification} from "@/axios/loading";
import downloadAxios from "@/axios/downloadFileAxios";

export default {
  name: 'StudentStudyInfo',
  data: () => {
    return {
      info: '',
      topicId: 0,
      currentPage: 1,
      page: 1,
      topicList: '',
      search: {
        name: '',
        date: '',
      },
      submitNum:{
        current:0,
        total:0
      },
      tableData: [],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
    }
  },
  watch: {
    topicId: {
      immediate: false,
      deep: true,
      handler() {
        this.searchAxios(0);
        axios.get("/api/task/count/"+this.topicId).then(resp=>{
          if (resp.data.code === 200) {
            this.submitNum=resp.data.data
          }

        }).catch(error=>{
          console.log(error)
        })
      }
    }
  },

  mounted() {

    axios.get("/api/topic/names").then(resp => {
      if (resp.data.code === 200) {
        this.topicList = resp.data.data
        this.topicList.length === 0 ? this.topicId = 0 : this.topicId = this.topicList[0].topicId
      }
    }).catch(error => {
      console.log(error)
    })

  },
  methods: {
    searchAxios(i) {
      if (this.search.date === '' && this.search.name === '' && i === 1) {
        startSuccessNotification('你还未选择条件！！！', '请选择条件后再输入。', 1)
        return
      }
      const params = {
        topicId: this.topicId,
        page: this.currentPage,
        name: this.search.name === '' ? null : this.search.name,
        start: this.search.date[0],
        end: this.search.date[1]
      }
      axios.get("/api/topic/student/search", {params}).then(resp => {

        if (resp.data.code === 200) {
          this.tableData = resp.data.data.records
          this.currentPage = resp.data.data.current
          this.page = resp.data.data.pages
        }
      }).catch(error => {
        console.log(error)
      })

    },
    tableRowClassName({row, rowIndex}) {
      if (rowIndex % 3 === 0 && rowIndex !== 0) {
        return 'warning-row';
      }
      return '';
    },
    handleEdit(index, row) {
      this.$router.push({
        name: 'showTaskInfoTeacher',
        params: {
          id: row.weeklyReportDTO.weeklyReport.weeklyReportId,
        }
      })

    },
    download(index, row) {
      console.log(row)
      downloadAxios(1, row.weeklyReportDTO.weeklyReport.weeklyReportId)
    }
  }
};
</script>

<style scoped lang="less">

</style>
<style>
.el-table .warning-row {
  background: #fcf9f3;
}

</style>