<template>
  <div class="hidden">
    <vs-sidebar v-model="active" open style="z-index: 100">
      <template #logo>
        <div class="logo">Never Settle</div>
      </template>
      <vs-sidebar-item
          v-for="e in noChild"
          :id="e.path"
          :key="e.title"
          :to="e.path"
      >
        <template #icon>
          <img :src="require('../assets/icon-svg/' + e.svg)"/>
        </template>
        {{ e.title }}
      </vs-sidebar-item>

      <vs-sidebar-group v-for="e in hasChild" :id="e.path" :key="e.title">
        <template #header>
          <vs-sidebar-item arrow>
            <template #icon>
              <img :src="require('../assets/icon-svg/' + e.svg)"/>
            </template>
            {{ e.title }}
          </vs-sidebar-item>
        </template>

        <vs-sidebar-item
            v-for="e2 of e.children"
            :id="e.path+e2.path"
            :key="e2.title"
            :to="e.path + e2.path"
        >
          <template #icon>
            <img
                :src="require('../assets/icon-svg/' + e2.svg)"
                style="margin: 0 15px 0 30px"
            />
          </template>
          {{ e2.title }}
        </vs-sidebar-item>
      </vs-sidebar-group>
    </vs-sidebar>
  </div>
</template>
<script>
import Cookies from "js-cookie";

export default {
  name: "CommAside",
  data() {
    return {
      active: "index",
      pageInfo:[]
    };
  },
  computed: {
    hasChild() {
      return this.pageInfo.filter((e) => {
        return e.children !== undefined
      })
    },
    noChild() {
      return this.pageInfo.filter((e) => {
        return e.children === undefined
      })
    },

  },
  mounted() {

      this.pageInfo=JSON.parse(Cookies.get("menu"))
  },
};
</script>

<style lang="less" scoped>
.logo {
  font-weight: 700;
  font-size: 20px;
  color: #195bff;
}

.icon {
  font-weight: 700;
}
</style>
