import {DomEditor} from '@wangeditor/editor'

const toolbarConfig = {
    excludeKeys: [
        "fullScreen",
        'group-image',
        'group-video',
        "insertLink",
        "insertVideo",
        'italic',
        'group-more-style' // 排除菜单组，写菜单组 key 的值即可
    ],
}
const editorConfig = {
    maxLength: 700,
    placeholder: '请输入内容...',
    MENU_CONF: {
    }
}

const show = (editor) => {
    const toolbar = DomEditor.getToolbar(editor)
    const curToolbarConfig = toolbar.getConfig()
    console.log(curToolbarConfig.toolbarKeys) // 当前菜单排序和分组

}

export {
    show, toolbarConfig, editorConfig
}