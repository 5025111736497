import Vue from 'vue'
let countLoading = 0;
//发请求时的加载动画
const startLoading = (text) => {

    let defaultConf = {
        color: '#000000',
        type: 'default',
        progress:'',
        text

    }
    if (countLoading === 0) {
    Vue.prototype.myLoad = Vue.prototype.$vs.loading(defaultConf);
        countLoading++;
    }

}
const endLoading = () => {
    if (countLoading === 1) {
        Vue.prototype.myLoad.close();
        countLoading--;
    }


}

let countNotification = 0;
let iconArr = [
    //成功
    {color: 'success', icon: `<i class='bx bx-laugh' style='color:#46C93A'  ></i>`},
    //警告
    {color: 'warn', icon: `<i class='bx bx-error' style='color:#FFBF12' ></i>`},
    //危险
    {color: 'danger', icon: `<i class='bx bx-error-circle' style='color:#FF4757' ></i>`}
]
const startErrorNotification = (state) => {
    if (state === 401) {
        if (countNotification === 1) return
        Vue.prototype.$vs.notification({
            position: 'top-center',
            color: iconArr[1].color,
            icon: iconArr[1].icon,
            flat: true,
            title: "请重新登录！！！",
            text: '即将跳转到登录界面',
            onDestroy: () => {
                countNotification--;
            }
        })
            Vue.prototype.$router.push({path: '/login'})


        countNotification++;
    }
    if (state === 404) {
        Vue.prototype.$vs.notification({
            position: 'top-center',
            color: iconArr[2].color,
            icon: iconArr[2].icon,
            flat: true,
            title: "页面未找到！！！",
            text: 'sorry！！！',
            onDestroy: () => {
                countNotification--;
            }
        })
    }
    if (state === 500 || state === undefined) {
        if (countNotification === 1) return
        Vue.prototype.$vs.notification({
            position: 'top-center',
            color: iconArr[2].color,
            icon: iconArr[2].icon,
            flat: true,
            title: "服务器异常！！！",
            text: '内部bug，请联系管理者！！！',
            onDestroy: () => {
                countNotification--;
            }
        })
        countNotification++;
    }
}
//状态码200的时候

const startSuccessNotification = (title, text, i) => {
    if (countNotification > 1) return
    //默认成功 绿色
    if (i === undefined) i = 0;
    countNotification++;
    Vue.prototype.$vs.notification({

        position: 'top-center',
        color: iconArr[i].color,
        icon: iconArr[i].icon,
        flat: true,
        title,
        text,
        onDestroy: () => {
            countNotification--
        }
    })
}
export {startLoading, endLoading, startErrorNotification, startSuccessNotification}

