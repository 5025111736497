<script>
export default {
  name: "WeeklyReportLIstHome"
}
</script>

<template>
<div>
  <router-view >
  </router-view>
</div>
</template>

<style scoped lang="less">

</style>