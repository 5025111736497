<template>
  <el-container>
    <el-aside width="250px">
      <comm-aside></comm-aside>
    </el-aside>
    <el-container>
      <el-header>
        <comm-header></comm-header>
      </el-header>
      <el-container>
        <el-main>
          <el-card style="border: transparent;padding: 20px"  > <router-view></router-view></el-card>
        </el-main>
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
import CommAside from '../components/CommAside.vue';
import CommHeader from '../components/CommHeader.vue';

export default {
  components: {CommAside, CommHeader},
  name: 'Home',
  data() {
    return {}
  },

}
</script>


