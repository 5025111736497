<template>
  <vs-dialog v-model="againActive">
    <template #header>
      <h4 class="not-margin">
        <slot name="title">
          {{title}}
        </slot>
      </h4>
    </template>
    
    <div style="height: 60px; display: flex;justify-content: right">
      <vs-button style="width: 80px" transparent @click="submit">
        确认
      </vs-button>
      <vs-button dark transparent @click="cancel">
        取消
      </vs-button>
    </div>
  </vs-dialog>
</template>


<script>
//实例： <AgainSubmit title="确定要删除吗？？？" :active="active" @cancelActive="active=false"  @submit="delWeeklyReport"></AgainSubmit>
export default {
  name: "AgainSubmit",
  data() {
    return {
      againActive: false,
    }
  },
  watch: {
    //父组件传来的true 则将againActive设置为true打开弹窗，传false 则关闭弹窗
    active(e) {
      this.againActive = e;
    },
    againActive(e) {
      //关闭的时候通知父组件
      if (e === false) {
        this.$emit("cancelActive")
      }
    }
  },
  computed: {
    childActive() {
      return this.againActive
    }
  },
  //title设置标题 ，active true/false 觉得弹窗的开关  submit 则表示点击确认的时候 执行的函数
  props: ['title', 'active'],

  methods: {
    //不能直接修改父组件active的值
    //点击取消按钮的时候 将againActive设置为 false 并给 父组件通知 让 props的active页设置为false
    cancel() {
      this.againActive = false;
      this.$emit("cancelActive")
    },
    submit() {
      this.$emit("submit")
    }
  }

}
</script>


<style lang="less" scoped>

</style>