<template>
  <div>
    <div>
      <vs-dialog v-model="active">
        <div style="width: 460px;display: flex;flex-direction: column;align-items: center">
          <h4>提交实习报告</h4>
          <div class="el-upload__tip" style="color:#F56C6C;margin: 10px 0">提示：只能上传一个文件；支持.doc,.docx, .zip文件</div>
          <div style="width: 360px">
            <el-upload
                drag
                ref="upload"
                action=""
                :on-remove="handleRemove"
                :limit="1"
                :before-upload="beforeAvatarUpload"
                :on-exceed="handleExceed"
                :on-change="handleChange"
                :auto-upload="false"
                :file-list="fileList">
              <div class="buts" style="margin: 60px 0">
                <button style="color: #195BFF" class="a">上传</button>
              </div>
              <!--        <div class="el-upload__text">将文件拖到此处，或<em style="color:#1C5DFF">点击上传</em></div>-->
            </el-upload>
          </div>
<!--          提交-->
          <div class="buts" style="margin: 30px 0 10px 0">
            <button style="color: #195BFF" class="a" @click="submit">提交</button>
          </div>
<!--          <vs-button @click="active2=!active2">-->
<!--            Open Dialog 2-->
<!--          </vs-button>-->
        </div>


      </vs-dialog>
      <vs-dialog v-model="active2">
        <template #header>
          <h4 class="not-margin">
            Welcome to <b>Vuesax</b>
          </h4>
        </template>
      </vs-dialog>
    </div>


  </div>


</template>

<script>

import {startSuccessNotification} from "@/axios/loading";
import axios from "axios";
import * as sectionFile from "@/utils/sectionFile";

export default {
  name: "TopicReport",
  data: () => {
    return {
      fileList: [],
      active2: false,
      file:'',
    }
  },
  computed:{
    active:{
      get(){
          return this.act;
      },
      set(val){
        console.log(val)
            this.$emit('cancelAct',val)
      }
    }
  }
  ,
  props:['act'],
  methods: {
    //删除时，清空file
    handleRemove() {
      this.file = ''
    },
    beforeAvatarUpload(file){
      console.log(file)
      return true
    },
    handleExceed(files, fileList) {
      startSuccessNotification('已添加过文件！！！', `当前文件数量 ${files.length + fileList.length} 个文件，如要继续添加，请删除旧文件后再添加新的文件`, 1)
    },
    //添加时 ，设置file
    handleChange(file) {
      if (file.size >= sectionFile.maxSize) {
        startSuccessNotification("文件大小不能超过 10MB",'',2)
        this.fileList.pop()
        return
      }
      this.file = file.raw
      console.log(this.file)

    },
    submit(){
      if (this.file === '') {
        startSuccessNotification('你还未添加如何文件！！！','请添加文件后再提交！！！',1);
        return
      }
      let formData = new FormData();
      formData.append('file',this.file)

      axios.post("/api/task/topicReport",formData,{ headers: {isFile: true}}).then(resp=>{
        if (resp.data.code === 200) {
          startSuccessNotification('添加成功！！！');
        }
        else
          startSuccessNotification('未知错误！！！','添加失败，请稍后重试',1)

      })
          .catch(error=>{
            console.log(error)
          })
          .finally(()=> {
              this.active=false
              setTimeout(()=>{this.$router.go(0)},300)

          })
    }
  }
}
</script>
<style scoped lang="less">
.buts {
  > button {
    width: 120px;
    height: 40px;
    border: 1px solid #409EFF;
    background-color: white;
    border-radius: 5px;


    &:hover {
      transition: all 0.4s;
      box-shadow: 0 0 8px 0 #195BFF;
    }
  }
}

</style>
