import axios from "axios";
import qs from "qs";
import {startErrorNotification} from "@/axios/loading";

// 请求拦截
axios.interceptors.request.use(config => {
    let accessToken = localStorage.getItem("accessToken");
    let refreshToken=localStorage.getItem("refreshToken");


    if (accessToken) {
        config.headers['token'] = accessToken;
    }
    if (refreshToken){
        config.headers['refreshToken']=refreshToken;
    }

    //设置post请求的请求头部信息, //不是上传文件的时候不修改
    if (!config.headers['isFile'] && (config.method === 'post' || config.method === 'put')) {
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
        config.data = qs.stringify(config.data);
    }
    return config;
}, error => {

    return Promise.reject(error);
})

// 响应拦截
axios.interceptors.response.use(response => {
    //token过期
    // if (response.data.code === 1000) {
    //     localStorage.setItem("accessToken",response.data.data.accessToken)
    //     localStorage.setItem("refreshToken",response.data.data.refreshToken)
    //     response.config
    //
    //
    // }


    if (response.headers['accesstoken']) {
        console.log(response.headers['accesstoken'])
             localStorage.setItem("accessToken",response.headers['accesstoken'])
    }
    if (response.headers['refreshtoken'])   {
        console.log(response.headers['refreshtoken'])
        localStorage.setItem("accessToken",response.headers['refreshtoken'])
    }
    return response;
}, error => {

    //弹窗
    startErrorNotification(error.response.status)
    return Promise.reject(error);
})