import Vue from 'vue'
import App from './App.vue'
import vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueRouter from 'vue-router';
import router from './router';
import store from './vuex/store.js';
import 'boxicons';
import './axios/axiosConfig'
import uploader from 'vue-simple-uploader'
Vue.use(uploader)
Vue.use(VueRouter);
Vue.use(ElementUI);
Vue.use(vuesax)
Vue.config.productionTip = false


new Vue({
    render: h => h(App),
    router,
    store: store,
    beforeCreate() {
        Vue.prototype.$bus = this
    }
}).$mount('#app')
