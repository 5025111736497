<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {};
  },
};
</script>

<style lang="less">
.cell-color{
  color:red
}

:root, :host {
  --w-e-textarea-bg-color: #F9F9F9;
}

.el-dialog {
  border-radius: 30px !important;
}

.el-textarea .el-textarea__inner:focus {
  border-color: #195bff
}

.box-inco {
  width: 20px;
  height: 20px;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}
.downLoad {
  border: none;
  width: 90px;
  height: 35px;
  border-radius: 5px;
  background-color: #CE3D3A;
  text-align: center;
  line-height: 35px;
  color: white;
  font-size: 14px;
  cursor: pointer
}
</style>
<style src="@wangeditor/editor/dist/css/style.css"></style>