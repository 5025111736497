import axios from "axios";

    const downloadAxios = (type ,id ) => {
        if (typeof type !== 'number' ||typeof id !=='number') return
        //1代表下载周报告，2代表下载课题报告
        axios.get("/api/task/download/+"+type+"/" + id, {responseType: 'blob'})
            .then((res) => {
                const {data, headers} = res
                const fileName = headers['content-disposition'].split("filename=")[1]
                // 此处当返回json文件时需要先对data进行JSON.stringify处理，其他类型文件不用做处理
                //const blob = new Blob([JSON.stringify(data)], ...)
                const blob = new Blob([data], {type: headers['content-type']})
                let dom = document.createElement('a')
                let url = window.URL.createObjectURL(blob)
                dom.href = url
                dom.download = decodeURI(fileName)
                dom.style.display = 'none'
                document.body.appendChild(dom)
                dom.click()
                dom.parentNode.removeChild(dom)
                window.URL.revokeObjectURL(url)
            }).catch((error) => {
            console.log(error)
        })
    }
export default downloadAxios