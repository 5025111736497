import SparkMD5 from 'spark-md5'
//默认单个文件最大10M
const maxSize = 1024*1024*10     //10MB;
const maxCount = 10000;
//切片
let splitCount = (fileSize) => {

    //切片数量
    let count = Math.ceil(fileSize / maxSize)

    // 大于100个则分100个
    if (count > maxCount) {

        return {
            count: maxCount, maxSize: fileSize / maxCount
        }
    }
    return {
        count, maxSize
    }
}
let splitFile = async (file) => {
    if (file === '') {
        return  false
    }
    console.log(file)
    //分片
    let countAndSize = splitCount(file.size);
    if (countAndSize.count === 1) {
        return false
    }
    //文件的md5
   let md5= await getFileMd5(file);
    //返回信息
    let r={
         fileInfo :{
            chunks: countAndSize.count,
            fileName:file.name,
            fileSize:file.size,
            md5:md5
        },
        cache:[]
    }
    for (let i = 0; i < countAndSize.count; i++) {
        let partFile = file.slice(i * (countAndSize.maxSize), (i + 1) * (countAndSize.maxSize))
        r.cache.push(
            {
                //文件
                partFile,
                //总片数
                chunks: countAndSize.count,
                //当前
                chunk: i + 1,
                code: md5+'-'+i,
                fileSuffix: file.name.substr(file.name.lastIndexOf(".")), //带 .  (.png)
                fileCodeName: md5+'-'+i+ file.name.substr(file.name.lastIndexOf("."))
            }
        )
    }
    console.log(r)
    return r
}

// 文件获取md5值
const getFileMd5 = async (file) => {
    return new Promise((resolve, reject) => {
        let fileReader = new FileReader()

        let sparkMd5 = new SparkMD5()
        fileReader.readAsArrayBuffer(file)
        fileReader.onload = () => {
            let md5 = sparkMd5.append(fileReader.result).end()
            resolve(md5)
        }
        fileReader.onerror = () => {
            reject('')
        }
    })

}


export {
    splitCount, splitFile,getFileMd5,maxSize
}