<template >
  <div >

    <el-page-header @back="signActive=!signActive" content="签到" v-if="signActive">
    </el-page-header >
    <template v-else>
      <h3 style="margin-top: 0">我的课题</h3>

      <div style="display: flex;flex-direction: column" v-if="topicInfo!==''">
        <h4 style="margin: 0;font-weight: 400">实践课题：<span>{{ topicInfo.topic.topicName }}</span></h4>
        <div style="display: flex;justify-content: space-between;height: 100px">
          <ul class="info">
            <li><span>实践编号：</span><span>{{ topicInfo.topic.topicId }}</span></li>
            <li><span>课程学时：</span><span>{{ topicInfo.topic.topicPeriod }} 周</span></li>
            <li><span>指导老师：</span><span v-for="(e,i) of topicInfo.teacherNames" :key="i">
              {{ i === topicInfo.teacherNames.length - 1 ? e : e + '，' }}
            </span></li>
            <li><span>课程人数：</span><span>{{ topicInfo.topic.memberNum }}</span></li>
          </ul>
          <div class="credit">
            <span style="text-align: center">{{ topicInfo.topic.topicCredit }}</span>
            <span style="font-weight: 600">学分</span>
          </div>
          <div style="display: flex;align-items: center;flex-direction: column ">
            <vs-button border style="width: 140px;height: 40px" to="scoreinfo">
              查看实习成绩
            </vs-button>
            <vs-button border color="#F67B7B" style="width:140px;height: 40px;" @click="downLoadFile">
              下载实习报告
            </vs-button>
          </div>
        </div>
        <div style="display: flex;height: 50px" >
          <span style="line-height: 50px;font-size: 14px">本周实习进展：</span>
          <span><vs-button border style="height: 35px" @click="signActive=!signActive">签到：
              <span style="color: #999999">{{ info.sign === 0 ? '未签到' : '已签到' }}</span>

            </vs-button></span>
          <span >
              <vs-button border style="height: 35px" to="weeklyreport" v-if="info.weeklyReport===0">周志：
              <span style="color: #999999">未提交</span>
            </vs-button>
            <vs-button border style="height: 35px" v-else>周志：
              <span style="color: #999999">已提交</span>
            </vs-button>
            </span>
          <span>

            <vs-button border style="height: 35px"  v-if="info.isFinalAcademicRecordFile===0"  @click="active1=true">实习报告：
            <span style="color: #ED5959">未提交</span></vs-button>

           <vs-button border style="height: 35px" v-else>实习报告：
            <span style="color: #999999">已提交</span></vs-button>
          <TopicReport :act="active1"  @cancelAct="val=>{active1=val}"  ></TopicReport>
          </span></div>
      </div>
      <el-empty v-else description="暂时未选如何课题"></el-empty>
    </template>
  </div>
</template>


<script>

import axios from "axios";
import {endLoading, startLoading, startSuccessNotification} from "@/axios/loading";
import TopicReport from "@/pages/UserPage/TopicReport.vue";

export default {
  name: "Task",
  components: {TopicReport},
  data: () => {
    return {
      signActive: false,
      active: 0,
      topicInfo: '',
      teacherNames: '',
      info: {
        sign: 0,
        weeklyReport: 0,
        isFinalAcademicRecordFile: 0
      },
      //文件弹窗
      active1:false,

    }
  },
  methods:{
    downLoadFile(){
      if (this.info.isFinalAcademicRecordFile === 0) {
        startSuccessNotification('无法下载！！！','你还未提交课题报告，请提交之后在下载',2)
        return
      }
      if (this.topicInfo.topic.topicId===undefined) return;

      console.log(this.topicInfo.topic.topicId);
      //1代表下载课题
      axios.get("/api/task/download/2/"+this.topicInfo.topic.topicId, {responseType: 'blob'})
          .then((res) => {
            const { data, headers } = res
            const fileName = headers['content-disposition'].split("filename=")[1]
            // 此处当返回json文件时需要先对data进行JSON.stringify处理，其他类型文件不用做处理
            //const blob = new Blob([JSON.stringify(data)], ...)
            const blob = new Blob([data], {type: headers['content-type']})
            let dom = document.createElement('a')
            let url = window.URL.createObjectURL(blob)
            dom.href = url
            dom.download = decodeURI(fileName)
            dom.style.display = 'none'
            document.body.appendChild(dom)
            dom.click()
            dom.parentNode.removeChild(dom)
            window.URL.revokeObjectURL(url)
          }).catch((error) => {
        console.log(error)})

    },


  },
  mounted() {

    startLoading()
    axios.get("/api/student/topic",).then(resp => {
      if (resp.data.code === 200) {
        this.topicInfo = resp.data.data


        this.teacherNames = resp.data.data.teacherNames
      }
    }).catch(error => {
      console.log(error)
    }).finally(() => {
      endLoading()
    })
    axios.get("/api/task/info").then(resp => {
      if (resp.data.code === 200) {
        this.info = resp.data.data
      }
    })
        .catch(error => {
          console.log(error);
        })
  }
}
</script>

<style scoped lang="less">
.info {
  list-style: none;
  width: 500px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-around;
  margin: 20px 0;
  font-size: 14px;

  > li {
    width: 200px;
  }

  > li span:nth-child(1) {
    color: #999999;
  }
}

.credit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 18px;
  color: #fb9018;
  border: 1px solid #fb9018;
  width: 100px;
  height: 100px;
  text-align: center;
  border-radius: 50px;

}
</style>